//alert('hi welcome');
var files;
var project_previous_category;
$( document ).ready(function() {
    // Tayyab author
    var imgs = $("img");
    for(i =0 ; i < imgs.length; i++){
        if(imgs[i].height < imgs[i].width){
            $("img").addClass('landscape');
        }
    }


    // End Tayyab


    var maxHeight = 0;

    $(".blocksh").each(function(){
        if ($(this).height() > maxHeight) {
            maxHeight = $(this).height();
        }
    });

    $(".blocksh").height(maxHeight);
    
    // reset forms on page reload
    //    $('form')[0].reset();
    //alert( "ready!" );
   
    // check all checboxes in notfication settings page
    var notification_settings_checkbox_state = true;
    $("#notification-settings-form").find("input[type=checkbox]").each(function(){
        if ($(this).prop('checked') == false){ 
            notification_settings_checkbox_state = false;
        }
    });
    
    if(notification_settings_checkbox_state){
        $('#notification-settings-form #select-all').hide();
        $('#notification-settings-form #deselect-all').show();
    }
    
    
    $('#notification-settings-form #select-all').click(function(){
        $('#notification-settings-form').find('input[type=checkbox]').prop('checked', true);
        $('#notification-settings-form #select-all').hide();
        $('#notification-settings-form #deselect-all').show();
    });
   
    // uncheck all checboxes in notfication settings page
    $('#notification-settings-form #deselect-all').click(function(){
        $('#notification-settings-form').find('input[type=checkbox]').removeAttr('checked')
        $('#notification-settings-form #deselect-all').hide();
        $('#notification-settings-form #select-all').show();
    });
    
    
   
       
    // for forgot password send
    $('#forgot_password_form').on('submit', function(){ 
        
        $('#forget-password-modal #info_messages').html('');
        $('#forget-password-modal #plz_wait_message').show();
        // ajax post method to pass form data to the 
       var request = $.post(
            $(this).prop('action'),        {
                "_token": $( this ).find( 'input[name=_token]' ).val(),
                "email": $( '#forgot_password_form #email' ).val()
            },
            function(data){
                //console.log(data);
                $('#forget-password-modal #plz_wait_message').hide();
                if(data.status == 'fail'){
                    html = '<div id="info_messages" class="alert alert-danger" role="alert">'+data.msg+'</div>';
                    
                }else{
                    $('#forgot_password_form')[0].reset();
                    html = '<div id="info_messages" class="alert alert-success" role="alert">'+data.msg+'</div>';
                }
                $('#info_messages').html(html);
            },
            'json'
            );
        request.error(function(httpObj, textStatus) {
            if(httpObj.status==401)
                window.location = '/user/sign-in';
        });
        return false;
    });
    $('#forget-password-link').on('click', function () {
        $('#forget-password-modal #info_messages').html('');
        $('#forgot_password_form')[0].reset();
    });
    
    // change user profile image, Show thumb
    
    $('.slct-single-img #change_image').on('click', function(){
        $('.slct-single-img #image_file').click();
    });
    
    $('body').on('change', '.slct-single-img #image_file', function() {

        var file = this.files[0];
        var fileType = file["type"];
        var ValidImageTypes = ["image/jpg", "image/jpeg", "image/png", "image/gif"];
        if ($.inArray(fileType, ValidImageTypes) < 0) {
            // invalid file type code goes here.
            $('.slct-single-img .vldt-err').addClass("error");
            $('.slct-single-img .vldt-err').html('Invalid type of file');
        }
        else {
            if (this.files && this.files[0]) {
                files = this.files[0];
                var reader = new FileReader();
                reader.onload = loadProfileThumb;
                reader.readAsDataURL(this.files[0]);
            }
            $('.slct-single-img .vldt-err').removeClass("error");
            $('.slct-single-img .vldt-err').html('');
        }
    });
    $('#project_category').on('click', function(){
          
        project_previous_category = $("#project_category option:selected").val();
    });
    $('#project_category').on('change', function(){
        
        selectedCategory = $("#project_category option:selected").text();
        selectedCategoryParent = $("#project_category option:selected").parent().attr('label');
        selectedText = selectedCategoryParent + ', ' +selectedCategory;
        $("#project_category option:selected").text(selectedText);
         
        var reset_val = $("#project_category option[value='"+project_previous_category+"']").text().split(',');
        reset_val = $.trim(reset_val[1]);
        $("#project_category option[value='"+project_previous_category+"']").text(reset_val) ;
        
    });

    function getFileExtension(name)
    {
        var found = name.lastIndexOf('.') + 1;
        return (found > 0 ? name.substr(found) : "");
    }
    
    // To Preview Image
    function loadProfileThumb(e) {

        var mimeType = e.target.result.split(",")[0].split(":")[1].split(";")[0].split("/")[0];

        if(mimeType == 'image'){

            $('.slct-single-img .change_image').show();
            $('.slct-single-img .upload_image').hide();
            $('.slct-single-img #image_thumb').remove();
            $('.slct-single-img #old_image_thumb').hide();

            if($('#pro_ject').val()=='project'){
                if(!$('.slct-single-img .offer-img').length){
                    $('.slct-single-img').prepend('<img id="image_thumb" src="'+ e.target.result +'"/>');
                }else{
                    $('.slct-single-img .offer-img').addClass('form-section-right');
                    $('.slct-single-img .offer-img').html('<img id="image_thumb" src="'+ e.target.result +'"/>');
                }
            }else{
                if(!$('.slct-single-img .offer-img').length){
                    $("#old_image_thumb").remove();
                    $("#upper_label").after('<div class="offer-img"></div>');
                    $('.slct-single-img .offer-img').addClass('form-section-right');
                    $('.slct-single-img .offer-img').html('<img class="profile-picture-aspect-ratio" id="image_thumb" src="'+ e.target.result +'"/>');
                }else{
                    $("#old_image_thumb").remove();
                    $('.slct-single-img .offer-img').addClass('form-section-right');
                    $('.slct-single-img .offer-img').html('<img class="profile-picture-aspect-ratio" id="image_thumb" src="'+ e.target.result +'"/>');
                }
            }
            $('#img_err').html('');
            $('.image-file.vldt-err').hide();
        //console.log($('#image_file').val());
        }else{
            //$('#img_err').css({'display':''})
            $('#img_err').html('Invalid file type or size');
            //$('#img_err').append('<p id="image_file-error" class="error">Invalid file type or size</p>');
            $('#image_thumb').hide();
        }
    }
    $('#delete-profile-image-modal #delete_profile_image').on('click', function(){
        var request = $.post(
            $(this).data('url'), 
            {
                "_token": $('#security_token').find( 'input[name=_token]' ).val(),
                "reference_id": $(this).data('reference-id')
            },
            function(data){
                if(data.status == 'success'){
                    $('.slct-single-img .delete_image').remove();
                    $('.slct-single-img .change_image').hide();
                    $('.slct-single-img .upload_image').show();
                    $('.slct-single-img #image_thumb').remove();
                    // $('.slct-single-img #old_image_thumb').remove();
                    // $('.slct-single-img .offer-img').remove();
                    var gender = $('input[type=radio][name=gender]:checked').val();
                    var genderVal = gender === undefined ? "default" : gender;
                    var newImage = "/uploads/users/images/"+genderVal+".png"
                    $("#old_image_thumb").attr("src",newImage);
                }
            },
            'json'
            );
        request.error(function(httpObj, textStatus) {
            if(httpObj.status==401)
                window.location = '/user/sign-in';
        });
    });
    
    $('.reset-btn').on('click', function(){
        $('.slct-single-img #image_thumb').remove();
        $('.slct-single-img .offer-img').remove();
        
        if($('.slct-single-img #old_image_thumb').length){
            $('.slct-single-img #old_image_thumb').show();
        }else{
            $('.slct-single-img .change_image').hide();
            $('.slct-single-img .upload_image').show();
        }
        
    });
    
    // Display need Category for Badge
    $("#select-need-category").change(function(){
        if ($("#select-need-category option:selected").val() == '') {
            $('.need-badge-chk').html('');
        }else{
            $('.need-badge-chk').html( $("#select-need-category option:selected").text() );
        }
    }).change();
    
    // Place Holder for DropDowns
    $("form select option[value='']").prop('disabled',true);
    $("form select").change(function(){
        if ($("form select option:selected").index() == 0) {
            $(this).addClass("grey")
        }else{
            $(this).removeClass("grey")
        }
    }).change();
    
    //    if ($("select option:selected").index() == 0) {
    //        $(this).addClass("grey")
    //    }else {
    //        $(this).removeClass("grey")
    //    }
    
    // disable city and country when golbal is checked
    $('#global').on('change', function(){
        if ($(this).prop('checked') == true){ 
            $('.glbl_adrs_itm').attr('disabled', 'disabled');
            $('.glbl_adrs_itm').addClass('grey');
            //$('.add_location_button').hide();
            //$('.new_added_location').remove();
            $('.glbl_adrs.vldt-err').html('');
            $('.form-section.location-section-zip').hide();

        }else{
            $('.glbl_adrs_itm').removeAttr('disabled');
            //$('.add_location_button').show();
            $('.form-section.location-section-zip').show();
        }
    }).change();


    // load cities on election of country
    $('#country').on('change', function(){
        var selected_country = $(this).val();
        if(selected_country != '' && selected_country != 0){
            $.ajax({
                type: "GET",
                url: '/get-cities-of-country/' + selected_country,
                dataType: 'json',
                success: function (data) {
                    if (typeof(data) != 'undefined' && data.status == 'success' && data.cities != '') {
                        cities_html = '<option selected disabled value="">Select City</option>';
                        $.each(data.cities, function (key, value) {
                            cities_html += '<option value="' + value.id + '"';
                            if ($('#city').data('old-city') == value.id) {
                                cities_html += ' selected="selected"';
                            }
                            cities_html += ' >' + value.city_name + '</option>';
                        });
                        $('#city').html(cities_html);
                    } else {
                        cities_html = '';
                        cities_html += '<option selected disabled value="">Select City</option>';
                        $('#city').html(cities_html);
                    }
                }
            });

            
        }else{
            //alert(selected_country);
            cities_html = '';
            cities_html += '<option selected disabled value="">Select City</option>';
            $('#city').html(cities_html);
        }
    }).change();


    // load cities on selection of country for create project special case
    $('.location_dropdown').on('change', function(){
        
        var selected_country = $(this).val();
        
        var element_complete_id = $(this).attr('id');
        element_complete_id_array = element_complete_id.split('-');
        element_id = element_complete_id_array[1];
        //alert(selected_country);
        if(selected_country != '' && selected_country != 0){
            
            $.ajax({
                type: "GET",
                url: '/get-cities-of-country/'+selected_country,
                dataType: 'json',
                success: function(data){
                    if(typeof(data) != 'undefined' && data.status == 'success' && data.cities != ''){
                        cities_html = '<option value="">Select City</option>';
                        $.each(data.cities, function(key, value)
                        {
                            cities_html += '<option value="'+value.id+'">'+value.city_name+'</option>';
                        }); 
                        
                        $('#city-'+element_id).html(cities_html);
                        
                    }
                }
            });
            
        }else{
            //alert(selected_country);
            cities_html = '';
            cities_html += '<option value="">Select City</option>';
            $('#city-'+element_id).html(cities_html);
        }
    });
    
    
    //    // comments editable
    //    $(".edit_comments").editable("/user/edit-comment", { 
    //        indicator : "<img src='img/indicator.gif'>",
    //        tooltip   : "Click to edit...",
    //        id     : 'element_id'
    //
    //    });



    //start complete need functionality
    //
    //check all checkboxes for need participants
    
    $('#checkallNeedParticipants:checkbox').change(function () {
        $('#complete-need-button-1').prop("disabled",false);
        $('#info_messages').hide();
        //alert($('#checkall').is(':checked'));
        if($('#checkallNeedParticipants').is(':checked')){ 
            $('#need_participants_from').find('input[type=checkbox]').prop('checked', true);
            
            total_participants_count = $('#total_participants_count').val();
            total_karma_points =  $('#total_karma_points').text();
            need_karma_points = $('#need_kp').val();
            left_karma_points = total_karma_points - (need_karma_points * total_participants_count);
            $('#selected_participants').text(total_participants_count);
            $('#left_karma_points').text(left_karma_points);
            if(left_karma_points < 0){
                html = '<div id="info_messages" class="alert alert-danger" role="alert">You have not sufficient karma points</div>';
                $('#info_messages').show();
                $('#info_messages').html(html);
                $('#complete-need-button-1').prop("disabled",true);
                
            }
  
        } else{ 
            $('#need_participants_from').find('input[type=checkbox]').removeAttr('checked');
            total_karma_points =  $('#total_karma_points').text();
            $('#left_karma_points').text(total_karma_points);
            $('#selected_participants').text(0);
        } 
    });
    
    $('#complete-need-button-1').click(function(){
        $('#info_messages').hide();
        $('#page1-need-btns').hide();
        $('#page2-need-btns').show();
        $('#page-1-content').hide();
        $('#page-2-content').show();
        
        
    });

    $('#need_participants_from').on('submit', function(){ 
        
        $('#need-complete-modal #info_messages').html('');
        $('#need-complete-modal #plz_wait_message').show();
        // ajax post method to pass form data to the 
        var request = $.post(
            $(this).prop('action'),        {
                "_token": $( this ).find( 'input[name=_token]' ).val(),
               
                "participants": $( this ).find('input[type=checkbox]:checked').serializeArray(),
                "need_id": $('#need_id').val() 
                
            },
            function(data){
                //console.log(data);
                $('#need-complete-modal #plz_wait_message').hide();
                if(data.status == 'fail'){
                    
                    $('#page1-need-btns').show();
                    $('#page2-need-btns').hide();
                    $('#page-1-content').show();
                    $('#page-2-content').hide();
                    html = '<div id="info_messages" class="alert alert-danger" role="alert">'+data.msg+'</div>';
                    
                }else{
                    //$('#forgot_password_form')[0].reset();
                    //$('#completion-link').html('Completed');
                    $('#completion-link').remove();
                    
                    $('.applynowbtn').removeAttr("onclick");
                    $('.applynowbtn').text("Completed");
                    
                    $('#page2-need-btns').hide();
                    $('#page-2-content').hide();
                    html = '<div id="info_messages" class="alert alert-success" role="alert">'+data.msg+'</div>';
                }
                $('#info_messages').show();
                $('#info_messages').html(html);
            },
            'json'
            );
        request.error(function(httpObj, textStatus) {
            if(httpObj.status==401)
                window.location = '/user/sign-in';
        });
        return false;
    });
    //end complete need functionality
    
    //start complete Wish functionality
    //
    //check all checkboxes for wish participants
    
    
    $('#checkallWishParticipants:checkbox').change(function () {
        $('#complete-wish-button-1').prop("disabled",false);
        $('#info_messages').hide();
        //alert($('#checkall').is(':checked'));
        if($('#checkallWishParticipants').is(':checked')){ 
            $('#wish_participants_from').find('input[type=checkbox]').prop('checked', true);
            
            total_participants_count = $('#total_participants_count').val();
            total_karma_points =  $('#total_karma_points').text();
            wish_karma_points = $('#wish_kp').val();
            left_karma_points = total_karma_points - (wish_karma_points * total_participants_count);
            $('#selected_participants').text(total_participants_count);
            $('#left_karma_points').text(left_karma_points);
            if(left_karma_points < 0){
                html = '<div id="info_messages" class="alert alert-danger" role="alert">You have not sufficient karma points</div>';
                $('#info_messages').show();
                $('#info_messages').html(html);
                $('#complete-wish-button-1').prop("disabled",true);
                
            }
  
        } else{ 
            $('#wish_participants_from').find('input[type=checkbox]').removeAttr('checked');
            total_karma_points =  $('#total_karma_points').text();
            $('#left_karma_points').text(total_karma_points);
            $('#selected_participants').text(0);
        } 
    });
    
    $('#complete-wish-button-1').click(function(){
        $('#info_messages').hide();
        $('#page1-wish-btns').hide();
        $('#page2-wish-btns').show();
        $('#page-1-content').hide();
        $('#page-2-content').show();
        
        
    });

    $('#wish_participants_from').on('submit', function(){ 
        
        $('#wish-complete-modal #info_messages').html('');
        $('#wish-complete-modal #plz_wait_message').show();
        // ajax post method to pass form data to the 
        var request = $.post(
            $(this).prop('action'),        {
                "_token": $( this ).find( 'input[name=_token]' ).val(),
               
                "participants": $( this ).find('input[type=checkbox]:checked').serializeArray(),
                "wish_id": $('#wish_id').val() 
                
            },
            function(data){
                //console.log(data);
                $('#wish-complete-modal #plz_wait_message').hide();
                if(data.status == 'fail'){
                    
                    $('#page1-wish-btns').show();
                    $('#page2-wish-btns').hide();
                    $('#page-1-content').show();
                    $('#page-2-content').hide();
                    html = '<div id="info_messages" class="alert alert-danger" role="alert">'+data.msg+'</div>';
                    
                }else{
                    //$('#forgot_password_form')[0].reset();
                    $('#completion-link').html('Completed');
                    $('#completion-link').removeAttr("onclick");
                    
                    $('#edit-wish-btn').remove();
                   
                    
                    $('#page2-wish-btns').hide();
                    $('#page-2-content').hide();
                    html = '<div id="info_messages" class="alert alert-success" role="alert">'+data.msg+'</div>';
                }
                $('#info_messages').show();
                $('#info_messages').html(html);
            },
            'json'
            );
        request.error(function(httpObj, textStatus) {
            if(httpObj.status==401)
                window.location = '/user/sign-in';
        });
        return false;
    });
    //end complete Wish functionality
    
    //start complete offer functionality
    //
    //check all checkboxes for offer participants
    
    
    $('#checkallOfferParticipants:checkbox').change(function () {

        //alert($('#checkall').is(':checked'));
        if($('#checkallOfferParticipants').is(':checked')){ 
            $('#offer_participants_from').find('input[type=checkbox]').prop('checked', true);
            
  
        } else{ 
            $('#offer_participants_from').find('input[type=checkbox]').removeAttr('checked');
        } 
    });
    
    $('#complete-offer-button-1').click(function(){
        $('#info_messages').hide();
        $('#page1-offer-btns').hide();
        $('#page2-offer-btns').show();
        $('#page-1-content').hide();
        $('#page-2-content').show();
        
        
    });

    $('#offer_participants_from').on('submit', function(){ 
        
        $('#offer-complete-modal #info_messages').html('');
        $('#offer-complete-modal #plz_wait_message').show();
        // ajax post method to pass form data to the 
        var request = $.post(
            $(this).prop('action'),        {
                "_token": $( this ).find( 'input[name=_token]' ).val(),
               
                "participants": $( this ).find('input[type=checkbox]:checked').serializeArray(),
                "offer_id": $('#offer_id').val() 
                
            },
            function(data){
                //console.log(data);
                $('#offer-complete-modal #plz_wait_message').hide();
                if(data.status == 'fail'){
                    
                    $('#page1-offer-btns').show();
                    $('#page2-offer-btns').hide();
                    $('#page-1-content').show();
                    $('#page-2-content').hide();
                    html = '<div id="info_messages" class="alert alert-danger" role="alert">'+data.msg+'</div>';
                    
                }else if(data.status == 'fail_users'){
                    $('#page1-offer-btns').show();
                    $('#page2-offer-btns').hide();
                    $('#page-1-content').show();
                    $('#page-2-content').hide();
                    error_html = '<br>';
                    
                    $.each(data.errorsArray, function( key, user ) {
                        //console.log(user);
                        error_html +='<span>'+ user +'</span><br>';
                    });
                    html = '<div id="info_messages" class="alert alert-danger" role="alert">'+data.msg+error_html+'</div>';
                }else{
                    //$('#forgot_password_form')[0].reset();
                    $('#completion-link').html('Completed');
                    $('#completion-link').removeAttr("onclick");
                    
                    $('#edit-offer-btn').remove();
                    
                    
                    $('#page2-offer-btns').hide();
                    $('#page-2-content').hide();
                    html = '<div id="info_messages" class="alert alert-success" role="alert">'+data.msg+'</div>';
                }
                $('#info_messages').show();
                $('#info_messages').html(html);
            },
            'json'
            );
        request.error(function(httpObj, textStatus) {
            if(httpObj.status==401)
                window.location = '/user/sign-in';
        });
        return false;
    });
    //end complete offer functionality
    
    
    //
    //start send karma points to project functionality
    $( "#karma_points" ).keyup(function() {
        total_karma_points = $('#total_karma_points').text();
        send_karma_points = $('#karma_points').val();
        left_karma_points = (total_karma_points - send_karma_points);
        if(send_karma_points < 0 || isNaN(send_karma_points) ){
            $('#send-karma-points-to-project-modal #info_messages').show();
            html = '<div id="info_messages" class="alert alert-danger" role="alert">Please enter valid amount of Karma Points</div>';
            $('#send-karma-points-to-project-modal #info_messages').html(html);
            left_karma_points = total_karma_points;
            $('#send-project-kp-button-1').prop("disabled",true);
        }else if(left_karma_points < 0){
            $('#send-karma-points-to-project-modal #info_messages').show();
            html = '<div id="info_messages" class="alert alert-danger" role="alert">You have insufficient Karma Points for this transfer.</div>';
            $('#send-karma-points-to-project-modal #info_messages').html(html);
            left_karma_points = 0;
            $('#send-project-kp-button-1').prop("disabled",true);
        }else{
            $('#send-karma-points-to-project-modal #info_messages').hide();
            $('#send-project-kp-button-1').prop("disabled",false);
        }
        $('#left_karma_points').text(left_karma_points);
    });
    
    $('#send-project-kp-button-1').click(function(){
        
        if($('#karma_points').val() == ''){
            $('#send-karma-points-to-project-modal #info_messages').show();
            html = '<div id="info_messages" class="alert alert-danger" role="alert">Enter something</div>';
            $('#send-karma-points-to-project-modal #info_messages').html(html);
        }else{
            $('#info_messages').hide();
            $('#page1-project-kp-btns').hide();
            $('#page2-project-kp-btns').show();
            $('#page-1-content').hide();
            $('#page-2-content').show();
        }
        
        
        
    });
    
    $('#send_karma_points_to_project_from').on('submit', function(){ 
        
        $('#send-karma-points-to-project-modal #info_messages').html('');
        $('#send-karma-points-to-project-modal #plz_wait_message').show();
        $('#page-2-content').hide();
        // ajax post method to pass form data to the 
        var request = $.post(
            $(this).prop('action'),        {
                "_token": $( this ).find( 'input[name=_token]' ).val(),
               
                "karma_points": $('#karma_points').val(),
                "project_id": $('#karma_points_project_id').val() 
                
            },
            function(data){
                //console.log(data);
                $('#send-karma-points-to-project-modal #plz_wait_message').hide();
                if(data.status == 'fail'){
                    
                    $('#page1-project-kp-btns').show();
                    $('#page2-project-kp-btns').hide();
                    $('#page-1-content').show();
                    $('#page-2-content').hide();
                    html = '<div id="info_messages" class="alert alert-danger" role="alert">'+data.msg+'</div>';
                    
                }else{
                    //$('#forgot_password_form')[0].reset();
                    $('#total_karma_points').text(data.new_karma_points);
                    $('#page2-project-kp-btns').hide();
                    $('#page-2-content').hide();
                    html = '<div id="info_messages" class="alert alert-success" role="alert">'+data.msg+'</div>';
                }
                $('#send-karma-points-to-project-modal #info_messages').show();
                $('#send-karma-points-to-project-modal #info_messages').html(html);
            },
            'json'
            );
        request.error(function(httpObj, textStatus) {
            if(httpObj.status==401)
                window.location = '/user/sign-in';
        });
        return false;
    });
    //end send karma points to project functionality
    //start send karma points to project functionality
    $('#complete-project-button-1').click(function(){
        $('#complete-project-modal #info_messages').html('');
        $('#complete-project-modal #plz_wait_message').show();
        jQuery.ajax({
            type: "POST",
            url: "/project/complete_project",
            data:({
                project_id:$('#complete_project_id').val()
            }),
            dataType: 'json',
            success: function(data){
                $('#complete-project-modal #plz_wait_message').hide();
                //console.log(data.other_projects); 
                html = ''
                if(data.status == 'fail'){
                    $('#page-1-complete-project-content').hide();
                    $('#page1-complete-project-btns').hide();
                    html = '<div id="info_messages" class="alert alert-danger" role="alert">'+data.msg+'</div>';
                }else if(data.status == 'success_karma_points_remains'){
                    $('#page-1-complete-project-content').hide();
                    $('#page1-complete-project-btns').hide();
                    projects_html = '';
                    projects_html +='<div class="need-on-popup">1. <a href="/create/need/'+$('#complete_project_id').val()+'">Create a need</a> - and the project will remain open.</div>';
                    
                    if(!isEmpty(data.other_projects) || !isEmpty(data.user_following_projects) || !isEmpty(data.user_participated_projects)){
                        projects_html +='<div class="need-on-popup">2. Transfer remaining karma points to another project you are involved in.</div>';
                        
                        projects_html +='<div class = "form-section-right selectinside popup-dropdown"><select name="receiver_project" id="receiver_project">';
                        projects_html +='<option value="">Select Project</option>';
                        if(!isEmpty(data.other_projects)){
                            console.log(data.other_projects);
                            projects_html +=' <optgroup label="My Projects">';
                            $.each(data.other_projects, function( key, project ) {
                                //projects_html +='<div><input type="radio" name="receiver_project" value="'+project.id+'">'+project.title+'</div>';
                                projects_html +='<option value="'+project.id+'">'+project.title+'</option>';   
                            });
                            projects_html +=' </optgroup>';
                        }
                        if(!isEmpty(data.user_following_projects)){
                            projects_html +=' <optgroup label="Following Projects">';
                            $.each(data.user_following_projects, function( key, project ) {
                                //projects_html +='<div><input type="radio" name="receiver_project" value="'+project.id+'">'+project.title+'</div>';
                                projects_html +='<option value="'+project.id+'">'+project.title+'</option>';   
                            });
                            projects_html +=' </optgroup>';
                        }
                        if(!isEmpty(data.user_participated_projects)){
                            projects_html +=' <optgroup label="Participated Projects">';
                            $.each(data.user_participated_projects, function( key, project ) {
                                //projects_html +='<div><input type="radio" name="receiver_project" value="'+project.id+'">'+project.title+'</div>';
                                projects_html +='<option value="'+project.id+'">'+project.title+'</option>';   
                            });
                            projects_html +=' </optgroup>';
                        }

                        projects_html +='</select></div>';

                        if(data.participaid_account==true){
                            projects_html +='<div class="need-on-popup">3. Complete the project without any of above options.</div>';
                        }

                        projects_html +='<div class="need-on-popup">3. Complete the project without any of above options. Notice that in this case your all above KP will transfer to Participaid account.</div>';
                    }else{
                        if(data.participaid_account==true){
                            projects_html +='<div class="need-on-popup">2. Complete the project without above option.</div>';
                        }
                        projects_html +='<div class="need-on-popup">2. Complete the project without above option. Notice that in this case your all above KP will transfer to Participaid account.</div>';
                    }
                    
                    $('#page-2-complete-project-content').html(projects_html);
                    $('#page-2-complete-project-content').show();
                    $('#page2-complete-project-btns').show();
                    
                    html = '<div id="info_messages" class="alert alert-danger" role="alert">'+data.msg+'</div>';
                }else{
                    $('#page-1-complete-project-content').hide();
                    $('#page1-complete-project-btns').hide();
                    html = '<div id="info_messages" class="alert alert-success" role="alert">'+data.msg+'</div>';
                }
                $('#complete-project-modal #info_messages').show();
                $('#complete-project-modal #info_messages').html(html);
            
            }
        });
        return false;
    });
    
    function isEmpty(obj) {
        for(var key in obj) {
            if(obj.hasOwnProperty(key))
                return false;
        }
        return true;
    }
    $('#offer-delete').click(function(){
        var r = confirm("These Offer will be permanently deleted and cannot be recovered. Are you sure?");
        if (r == true) {
            return true;
        } else {
            return false;
        }
    });

    //comlete the project
    $('#complete-project-button-2').click(function(){
        $('#complete-project-modal #info_messages').html('');
        $('#complete-project-modal #plz_wait_message').show();
        jQuery.ajax({
            type: "POST",
            url: "/project/complete_project_transfer",
            data:({
                project_id:$('#complete_project_id').val(),
                receiver_project_id: $('#receiver_project').val()
            }),
            dataType: 'json',
            success: function(data){
                $('#complete-project-modal #plz_wait_message').hide();
                //console.log(data); 
                html = ''
                if(data.status == 'fail'){
                    $('#page-2-complete-project-content').hide();
                    $('#page2-complete-project-btns').hide();
                    html = '<div id="info_messages" class="alert alert-danger" role="alert">'+data.msg+'</div>';
                
                }else{
                    $('#page-2-complete-project-content').hide();
                    $('#page2-complete-project-btns').hide();
                    html = '<div id="info_messages" class="alert alert-success" role="alert">'+data.msg+'</div>';
                }
                $('#complete-project-modal #info_messages').show();
                $('#complete-project-modal #info_messages').html(html);
            
            }
        });
        return false;
    });
    $('select').css("color","black");
});



function needCountParticipant(obj){
    $('#complete-need-button-1').prop("disabled",false);
    $('#info_messages').hide();
    participants = $('#selected_participants').text();
    total_karma_points =  $('#total_karma_points').text();
    need_karma_points = $('#need_kp').val();
    
    if($(obj).is(':checked')){ 
        new_participants = ++participants;

    }else{
        
        new_participants = --participants;
    }
    left_karma_points = total_karma_points - (need_karma_points * new_participants);
    $('#selected_participants').text(new_participants);
    $('#left_karma_points').text(left_karma_points);
    if(left_karma_points < 0){
        html = '<div id="info_messages" class="alert alert-danger" role="alert">You have not sufficient karma points</div>';
        $('#info_messages').show();
        $('#info_messages').html(html);
        $('#complete-need-button-1').prop("disabled",true);
                
    }
}


function wishCountParticipant(obj){
    $('#complete-wish-button-1').prop("disabled",false);
    $('#info_messages').hide();
    participants = $('#selected_participants').text();
    total_karma_points =  $('#total_karma_points').text();
    wish_karma_points = $('#wish_kp').val();
    
    if($(obj).is(':checked')){ 
        new_participants = ++participants;

    }else{
        
        new_participants = --participants;
    }
    left_karma_points = total_karma_points - (wish_karma_points * new_participants);
    $('#selected_participants').text(new_participants);
    $('#left_karma_points').text(left_karma_points);
    if(left_karma_points < 0){
        html = '<div id="info_messages" class="alert alert-danger" role="alert">You have not sufficient karma points</div>';
        $('#info_messages').show();
        $('#info_messages').html(html);
        $('#complete-wish-button-1').prop("disabled",true);
                
    }
}
//use for complete need
function openNeedCompletionPopup(){
    $('#complete-need-button-1').prop("disabled",false);
    total_karma_points =  $('#total_karma_points').text();
    $('#need_participants_from').find('input[type=checkbox]').removeAttr('checked');
    $('#checkallNeedParticipants').removeAttr('checked');
    $('#selected_participants').text(0);
    $('#left_karma_points').text(total_karma_points);
    
    $('#page1-need-btns').show();
    $('#page2-need-btns').hide();
    $('#page-1-content').show();
    $('#page-2-content').hide();
    $('#info_messages').hide();
    
    $('#need-complete-modal').modal({
        keyboard: false
    })
}

//use for complete wish
function openWishCompletionPopup(){
    
    $('#complete-wish-button-1').prop("disabled",false);
    total_karma_points =  $('#total_karma_points').text();
    $('#wish_participants_from').find('input[type=checkbox]').removeAttr('checked');
    $('#checkallWishParticipants').removeAttr('checked');
    $('#selected_participants').text(0);
    $('#left_karma_points').text(total_karma_points);
    
    $('#page1-wish-btns').show();
    $('#page2-wish-btns').hide();
    $('#page-1-content').show();
    $('#page-2-content').hide();
    $('#info_messages').hide();
    
    $('#wish-complete-modal').modal({
        keyboard: false
    })
}

//use for complete offer
function openOfferCompletionPopup(){
    
    $('#offer_participants_from').find('input[type=checkbox]').removeAttr('checked');
    $('#checkallOfferParticipants').removeAttr('checked');
    $('#page1-offer-btns').show();
    $('#page2-offer-btns').hide();
    $('#page-1-content').show();
    $('#page-2-content').hide();
    $('#info_messages').hide();
    
    $('#offer-complete-modal').modal({
        keyboard: false
    })
}

//used for send karma points to project
function openSendKarmaPointsPopup(){
    total_karma_points = $('#total_karma_points').text();
    $('#left_karma_points').text(total_karma_points);
    $('#send-project-kp-button-1').prop("disabled",false);
    $('#karma_points').val('');
    $('#page1-project-kp-btns').show();
    $('#page2-project-kp-btns').hide();
    $('#page-1-content').show();
    $('#page-2-content').hide();
    $('#info_messages').hide();
    $('#send-karma-points-to-project-modal').modal({
        keyboard: false
    })
}
//used for complete project
function openCompleteProjectPopup(){
    
    $('#complete-project-modal #info_messages').hide();
    $('#page-1-complete-project-content').show();
    $('#page1-complete-project-btns').show();
    $('#page-2-complete-project-content').hide();
    $('#page2-complete-project-btns').hide();
    
    $('#complete-project-modal').modal({
        keyboard: false
    })
}

function likeOrUnlikeMedia(media_id){

    jQuery.ajax({
        type: "POST",
        url: "/user/like-or-unlike-media",
        data:({
            media_id:media_id
        }),
        dataType: 'json',
        success: function(data){
            //console.log(data); 
            html = ''
            if(data.status == 'success'){
                if(data.action == 'like'){
                    html += '<a class="heart liked" href="javascript:void(0);" onclick="likeOrUnlikeMedia('+media_id+');" title="Unlike"></a><span class="pointer" onclick="getMediaLikesUsers('+media_id+');">'+data.current_likes_count+'</span>';
                     
                }else{
                    html += '<a class="heart" href="javascript:void(0);" onclick="likeOrUnlikeMedia('+media_id+');" title="Like"></a><span class="pointer" onclick="getMediaLikesUsers('+media_id+');">'+data.current_likes_count+'</span>';
                }
               
                $("#media_count_"+media_id).html(html); 
            }else{
                alert(data.msg); 
            }
            
            
        }
    });
    return false;
}
function deleteMedia(media_id,id,page_from){
    //alert(post_id);
    media_type = $('#media_type').val();
    jQuery.ajax({
        type: "POST",
        url: "/user/delete-media",
        data:({
            media_id:media_id,
            media_type: media_type
        }),
        dataType: 'json',
        success: function(data){
            //console.log(data); 
            if(data.status == 'success'){
                if(page_from == 'gallery_detail'){
                    window.location = '/user/profile-gallery/'+id;
                }else if(page_from == 'project_gallery_detail'){
                    window.location = '/project/gallery/'+id;
                }else{
                    $("#media_"+media_id).remove();
                }
                 
            }else{
                $('#error_messages').html(data.msg); 
            }
            $('#delete-gallery-confirmation-modal').modal('hide');
            
            
        }
    });
    return false;	
   
}
function openDeleteConfirmationPopup(media_resource_id){
    $('#delete-gallery-confirmation-modal').modal({
        keyboard: false
    });
    $('#delete_confirm').attr('onclick','deleteMedia('+media_resource_id+')');
    return false;
}

$(document).ready(function() {
    var max_fields      = 3; //maximum input boxes allowed
    var wrapper         = $(".location_fields_wrap"); //Fields wrapper
    var add_button      = $(".add_location_button"); //Add button ID
    count = $('#locations_count').val();
    count = count-0
    //alert();
    var x = --count; //initlal text box count
    //alert(x);
    $(add_button).click(function(e){ //on add input button click
        e.preventDefault();
        //alert('dddddddddddddd');
        if(x < max_fields){ //max input box allowed

            x++; //text box increment
            
            $.ajax({
                type: "GET",
                url: '/get-countries',
                dataType: 'json',
                success: function(data){
                    countries_html = '';
                    if(data.status == 'success'){
                        //console.log(data);
                        $('#dynamic_location_count').val(x);
                        countries_html = '<option value="">Select Country</option>';
                        $.each(data.countries, function(key, value)
                        {
                            //alert(key);
                            countries_html += '<option value="'+key+'">'+value+'</option>';
                        }); 
                        html = '<div class="new_added_location"><label class="control-label form-section-left">Location '+x+' </label>';
                        html += '<div class="form-section-right add-section set-marg">';
            
                        html += '<div class="add-sec-bottom">';
                        html += '<select class="more_country glbl_adrs_itm" id="country-'+x+'" name="country[]">'+countries_html+'</select>';
                        html += '</div>';
            
                        html += '<div class="add-sec-bottom">';
                        html += '<select id="city-'+x+'" name="city[]" class="glbl_adrs_itm"><option value="">Select City</option></select>';
                        html += '</div>';
            
                        html += '<div class="add-sec-bottom">';
                        html += '<input id="zip-'+x+'" type="text" value="" name="zip[]" placeholder="Zip" class="glbl_adrs_itm">';
                        html += '</div>';
            
                        html += '</div>';
                        html += '<a href="#" class="remove_field" title="Remove">X</a></div>';
                        $(wrapper).append(html); //add input box
                        if(x == max_fields){
                            $('.add_location_button').hide();
                        }
 
                    }
                }
            });
            
        }
    });
   
    $(wrapper).on("click",".remove_field", function(e){ //user click on remove text
        e.preventDefault();
        $(this).parent('div').remove();
        //console.log($(this).parent('div'));
        x--;
        
        $('#dynamic_location_count').val( $('#dynamic_location_count').val() - 1 );
        //alert(x);
        if(x < max_fields){
            $('.add_location_button').show();
        }
    })
    
    $(document).on("change",".more_country", function(e){
        element_id = e.target.id;
        element_id_array = element_id.split('-');
        x = element_id_array[1];
        country_id = $('#'+element_id).val();
        //alert(country_id);
        
        if(country_id != '' && country_id != 0){
            
            $.ajax({
                type: "GET",
                url: '/get-cities-of-country/'+country_id,
                dataType: 'json',
                success: function(data){
                    if(typeof(data) != 'undefined' && data.status == 'success' && data.cities != ''){
                        cities_html = '<option value="">Select City</option>';
                        $.each(data.cities, function(key, value)
                        {
                            cities_html += '<option value="'+value.id+'">'+value.city_name+'</option>';
                        }); 
                        
                        $('#city-'+x).html(cities_html);
                        
                    }
                }
            });
            
        }else{
            //alert(selected_country);
            cities_html = '';
            cities_html += '<option value="">Select City</option>';
            $('#city-'+x).html(cities_html);
        }
        
    });
    //########################################################
    //####    Start global participaid search functionality ####
    //########################################################

    $('#global_search_text').keypress(function (e) {
        
        if (e.which == 13) {
            
            e.stopPropagation(); // Stop stuff happening
            e.preventDefault(); 
            $('#global-search-participaid').click();
            return false;    //<---- Add this line
        }
    });
    
    //    $('.pagination').on('click', function () {
    //        alert('ddddddddddddd');
    //    });
    $('#global-search-participaid').on('click', function () {

        global_search_type = $('#global_search_type').val();
        global_search_text = $('#global_search_text').val();
        
        if(global_search_type == ''){
            type = 'need';   
        }
        
        
        location.href = '/global_search/'+global_search_type+'/'+global_search_text;
    });
    //########################################################
    //####          End global participaid search functionality            ####
    //########################################################

    //########################################################
    //####    Start Main participaid search functionality ####
    //########################################################

    $('#search_text').keypress(function (e) {
        
        if (e.which == 13) {
            
            e.stopPropagation(); // Stop stuff happening
            e.preventDefault(); 
            $('#search-participaid').click();
            return false;    //<---- Add this line
        }
    });
    
    //    $('.pagination').on('click', function () {
    //        alert('ddddddddddddd');
    //    });
    $('#search-participaid').on('click', function () {

        type = $('#search_type').val();
        advance_box = $('#advance_box').val();
        search_text = $('#search_text').val();
        participaid_category_filter = $('#participaid-category-filter').val();
        participaid_location_filter = $('#participaid-location-filter').val();
        participaid_language_filter = $('#participaid-language-filter').val();
        
        if(type == ''){
            type = 'project';   
        }
        if(search_text == ''){
            search_text = 'all';
        }
        
        if(participaid_category_filter == '-1'){
            participaid_category_filter = 'all';
        }
        if(participaid_location_filter == ''){
            participaid_location_filter = 'all';
        }
        
        location.href = '/search/'+type+'/'+advance_box+'/'+search_text+'/'+participaid_category_filter+'/'+participaid_location_filter+'/'+participaid_language_filter;
    });
    
    $('#participaid-adv-search').click(function () {
        if(!$("#participaid-adv-search-box").is(':hidden')){
            $('#advance_box').val('no');
            if ($("#participaid-category-filter option:selected").val() != '-1' || $("#participaid-location-filter option:selected").val() != '' || $("#participaid-language-filter option:selected").val() != '') {
                $('#participaid-advance-search-confirmation-modal').modal({
                    keyboard: false
                });
            }
         
        }else{
            $('#advance_box').val('yes');
        }
        $(".participaid-adv-search-box").slideToggle();
    });
    $('#resetSearch').click(function (){ 
        $('#participaid-category-filter').val('-1');
        $('#participaid-location-filter').val('');
        $('#participaid-language-filter').val('');
        $('#search_text').val('');
        $('#search-participaid').click();
    });
    
        
    //########################################################
    //####          End Main participaid search functionality            ####
    //########################################################


    //########################################################
    //####          Deactivate Account Modal              ####
    //########################################################
    $('#deactivate-account-link').on('click', function () {
        
        if($('#deactivate_form #deactivate_current_password').val() == ''){
            $('#deactivate_form #password-error').html('<p class="error">required</p>');
            return false;
        }else{
            $('#deactivate-account-modal #reason').val('');
            $('#deactivate-account-modal #other').val('');
            $('#deactivate-account-modal #info-messages').html('');
        }
    });
    $('#deactivate_form').on('submit', function(){
        if($('#deactivate-account-modal #reason').val() == '' && $('#deactivate-account-modal #other').val() == ''){
            $('#deactivate-account-modal #info-messages').html('<div class="alert alert-danger">We are sad to see you go! Can you tell us why you have chosen to deactivate?</div>');
            return false;
        }
    });
    $('#deactivate_form .close-modal').on('click', function () {
        $('#deactivate_form')[0].reset();
        $('#deactivate_form #password-error').html('');
        $('#deactivate-account-modal #info-messages').html('');
    });
    //########################################################
    //####      End of Deactivate Account Modal           ####
    //########################################################
    
    
    //########################################################
    //####        New Comment Need/Offer/Wish             ####
    //########################################################
    $( document ).on( "keypress", "#need_new_comment #comment_text", function(e) {
        if (e.which == 13) {
            $('#need_new_comment #add_comment').click();
        }
    });
    
    $('#need_new_comment #add_comment').on('click', function(){

        var auth_user = $('#auth-user').val();
        var comment_text = $('#need_new_comment #comment_text').val();

        if(comment_text != '' && auth_user!= ''){
            $('#need_new_comment #comment_text').val('');
            var request  = $.post(
                $(this).data('url'), 
                {
                    "_token": $('#security_token').find( 'input[name=_token]' ).val(),
                    "item_id": $(this).data('item-id'),
                    "comment_text": comment_text
                },
                function(data){
                    if(data.status == 'success'){
                        var comment_html = '<div class="comment-from comment-fixed-height" id="comment_'+data.new_comment_id+'">'+
                        '<img  src="'+ $('#auth-user-profile-picture').attr('src') +'">'+
                        '<p>'+
                        '<span class="only_comment_text" id="comment_text_'+data.new_comment_id+'">'+comment_text+'</span> '+
                        '<a class="delete-comment cursor-pointer" data-delete-url="/need-offer/delete-comment" data-comment-id="'+data.new_comment_id+'"></a> '+
                        '<a class="edit-comment edit-need-cmnt cursor-pointer" data-comment-id="'+data.new_comment_id+'"></a> '+
                        '<div id="edit_need_comment_div_'+data.new_comment_id+'" class="edit-comment-div" style="display:none">'+
                        '<input class="edit_need_comment_text" id="new_need_comment_'+data.new_comment_id+'" type="text" value="'+comment_text+'" name="new_need_comment" data-comment-id="'+data.new_comment_id+'">'+
                        '<button id="edit_need_comment_btn_'+data.new_comment_id+'" class="edit_need_comment_btn" type="button" data-edit-url="/need-offer/edit-comment" data-comment-id="'+data.new_comment_id+'">Update</button>'+
                        '</div>'+
                        '</p>'+
                        '</div>';
                        $('#need_new_comment').before(comment_html);
                    }else{
                        $('#need-comment-response-modal .modal-body').html('<div id="info_messages" class="alert alert-danger" role="alert">'+ data.msg +'</div>');    
                        $('#need-comment-response-modal').modal({
                            keyboard: false
                        })
                    }
                },
                'json'
                );
                
        }else{
            if(auth_user == '') {
                window.location = '/user/sign-in';
            }else {
            $('#need-comment-response-modal .modal-body').html('<div id="info_messages" class="alert alert-danger" role="alert">Please enter text here</div>');
            $('#need-comment-response-modal').modal({
                keyboard: false
            })
        }
        }

        request.error(function(httpObj, textStatus) {
            if(httpObj.status==401)
                window.location = '/user/sign-in';
        });
    });
    //########################################################
    //####      End of New Comment Need/Offer/Wish        ####
    //########################################################
    
    
    //########################################################
    //####        Edit Need/Offer/Wish Comment            ####
    //########################################################
    updt_cmnt_btn_clkd = false;
    $( document ).on( "focusout", ".edit_need_comment_text", function(e){
        if(!(updt_cmnt_btn_clkd)){
            $('.edit-comment-div').hide();
            $('.only_comment_text').show();
            $(this).val( $(this).parents('.comment-from').find('.only_comment_text').html() );
        }else{
            updt_cmnt_btn_clkd = false;
        }
    });
    $( document ).on( "mousedown", ".edit_need_comment_btn", function(e){
        updt_cmnt_btn_clkd = true;
    });
    
    $( document ).on( "click", ".edit-need-cmnt", function(){
        var comment_id = $(this).data('comment-id');
        $('#comment_text_'+comment_id).hide();
        $('#edit_need_comment_div_'+comment_id).show();
        $('#new_need_comment_'+comment_id).focus();
        return false; 
    });
    
    $( document ).on( "keypress", ".edit_need_comment_text", function(e) {
        var comment_id = $(this).data('comment-id');
        if (e.which == 13) {
            $('#edit_need_comment_btn_'+comment_id).click();
        }
    });
    
    $( document ).on( "click", ".edit_need_comment_btn", function() {
        var edit_url = $(this).data('edit-url');
        var comment_id = $(this).data('comment-id');
        var comment_text = $('#new_need_comment_'+comment_id).val();
            
        if(comment_text != ''){
           var request = $.post(
                edit_url, 
                {
                    "_token": $('#security_token').find( 'input[name=_token]' ).val(),
                    "comment_id": comment_id,
                    "comment_text": comment_text
                },
                function(data){
                    if(data.status == 'success'){
                            
                        $('#comment_text_'+comment_id).html(comment_text);
                        $('#comment_text_'+comment_id).show();
                        //                        $('#edit_need_comment_div_'+comment_id).hide();
                        $('.edit-comment-div').hide();
                            
                    }else{
                        $('#need-comment-response-modal .modal-body').html('<div id="info_messages" class="alert alert-danger" role="alert">'+ data.msg +'</div>');    
                        $('#need-comment-response-modal').modal({
                            keyboard: false
                        })
                    }
                },
                'json'
                );

        }else{
            $('#need-comment-response-modal .modal-body').html('<div id="info_messages" class="alert alert-danger" role="alert">Please enter text here</div>');
            $('#need-comment-response-modal').modal({
                keyboard: false
            });
        }
        request.error(function(httpObj, textStatus) {
            if(httpObj.status==401)
                window.location = '/user/sign-in';
        });
        return false;
    });
    //########################################################
    //####       End of Edit Need/Offer/Wish Comment      ####
    //########################################################
    
    
    
    //########################################################
    //####         Delete Need/Offer/Wish Comment         ####
    //########################################################
    $( document ).on( "click", ".need-all-comments .delete-comment", function() {
        var delete_url = $(this).data('delete-url');
        var comment_id = $(this).data('comment-id');
        $('#need-comment-response-modal .modal-body').html('Are you sure you want to delete this comment?');
        $('#need-comment-response-modal #dlt-nd-cmnt-mdl-btn').attr('delete-url', delete_url);
        $('#need-comment-response-modal #dlt-nd-cmnt-mdl-btn').attr('comment-id', comment_id);
        $('#need-comment-response-modal #dlt-nd-cmnt-mdl-btn').show();
        $('#need-comment-response-modal').modal({
            keyboard: false
        })
    });
    
    $('.nd-dlt-cmnt-close').on('click', function() {
        $('#need-comment-response-modal #dlt-nd-cmnt-mdl-btn').attr('comment-id', '');
        $('#need-comment-response-modal #dlt-nd-cmnt-mdl-btn').hide();
    });
    
    $( document ).on( "click", ".dlt-need-cmnt", function() {
        
        var delete_url = $(this).attr('delete-url');
        var comment_id = $(this).attr('comment-id');
        $('.nd-dlt-cmnt-close').click();
        var request = $.post(
            delete_url, 
            {
                "_token": $('#security_token').find( 'input[name=_token]' ).val(),
                "comment_id": comment_id
            },
            function(data){
                
                if(data.status == 'success'){
                    $('#comment_'+comment_id).remove();    
                    $('#need-comment-response-modal .close').click();
                }else{
                    $('#need-comment-response-modal .modal-body').html('<div id="info_messages" class="alert alert-danger" role="alert">'+ data.msg +'</div>');    
                //                    $('#need-comment-response-modal').modal({
                //                        keyboard: false
                //                    })
                }
            },
            'json'
            );
        request.error(function(httpObj, textStatus) {
            if(httpObj.status==401)
                window.location = '/user/sign-in';
        });
    });
    //########################################################
    //####     End of Delete Need/Offer/Wish Comment      ####
    //########################################################
    
    
    //########################################################
    //####                   Karma Shop                   ####
    //########################################################
    //    $('.karma-shop-search-btn').on('click', function(){
    //        var search_type = $(this).data('search-type');
    //        var search_text = '';
    //        if(!$(this).hasClass('no-text') && $('#karma-shop-search-text').length){
    //            search_text = $('#karma-shop-search-text').val();
    //        }
    //        window.location.href = '/karma-shop/'+search_type+'/'+search_text
    //    });
    $('#karma-shop-search-text').val('');
    $( document ).on( "keyup", "#karma-shop-search-text", function(e) {
        $('#karma-shop-start-search-btn').click();
    });
    
    $( document ).on( "change", "#karma-shop-location-filter", function(e) {
        $('#karma-shop-start-search-btn').click();
    });
    
    $( document ).on( "change", "#karma-shop-category-filter", function(e) {
        $('#karma-shop-start-search-btn').click();
    });
    
    $('.adv-search').click(function () {
        if(!$(".adv-search-box").is(':hidden')){
          
            if ($("#karma-shop-location-filter option:selected").val() != '' || $("#karma-shop-category-filter option:selected").val() != '') {
                $('#advance-search-confirmation-modal').modal({
                    keyboard: false
                });
            }
          
        }
        $(".adv-search-box").slideToggle();
    });
    
    $('#reset_advance_search').click(function(){
        $('#karma-shop-location-filter option[value=""]').prop('selected', true);
        $('#karma-shop-category-filter option[value=""]').prop('selected', true);
        $('#karma-shop-start-search-btn').click();
    });
    
    $('.get-karma-shop-ajax').on('click', function(){
        
        var element = this;
        var location_filter = $('#karma-shop-location-filter').val();
        var category_filter = $('#karma-shop-category-filter').val();
        var search_text = $('#karma-shop-search-text').val();
        var shop_type = $(this).data('shop-type'); // need,offer,wish
        var data_type = $(this).data('data-type'); // load_more, new_data

        
        var current_page = '1';
        if(data_type == 'load_more'){
            var total_pages = $(this).data('total-pages');
            current_page = parseInt($(this).data('current-page'), 10);
            $(this).hide(); //hide load more button on click
        }else{
            $('.shop-items-container').html('');
            $('.karma-shop-tabs').removeClass('active');
            $('.karma-shop-tabs.'+shop_type).addClass('active');
            $('#karma-shop-start-search-btn').data('shop-type', shop_type);
        }
        
        
        //$('.animation_image').show(); //show loading image
        $('.no-data-found').html('');

        var request = $.post(
            '/karma-shop-ajax',
            {
                "_token": $('#security_token').find( 'input[name=_token]' ).val(),
                "shop_type": shop_type,
                "search_text": search_text,
                "current_page": current_page,
                "data_type": data_type,
                "category_filter": category_filter,
                "location_filter": location_filter
            },
            function(data){


                $('.animation_image').hide(); //show loading image

                if(data.status == 'success'){

                    if(data.search_result.length){


                        var ajax_shop_html = '';
                        var details_page_link = '';
                        var display_image = '';
                        console.log(data.search_result);
                        if(shop_type == 'need'){
                            if(current_page == 1 && data_type != 'load_more') {
                                ajax_shop_html += '<div class="search-individual-box" style="margin-top: 0px; margin-bottom: 20px;">A <b>Need</b> is vital for the creation and success of a social project. <br/><br/>\n' +
                                    '\n' +
                                    'This can be best explained through an <b>example</b>: if an organization wants to build a school for disabled children, they will need teachers and equipment for the school, a curriculum, books, toys, other school supplies and financial assistance. All these are examples of „Needs“ that can be asked for and obtained through ParticiPaid and its vast global network.</div><br/>\n';
                            }
                        }

                        if (shop_type == 'offer'){
                            if(current_page == 1 && data_type != 'load_more') {
                                ajax_shop_html += '<div class="userDetails ks-details ks-landing" style="margin-top: 0px; margin-bottom: 20px;">Did you create good Karma by participating in a social project? Our Karma Shop offers great value products and services which can be received in exchange for the <b>Karma Points</b> you have gathered! \n' +
                                    '\n' +
                                    '<br/><br/>\n' +
                                    '\n' +
                                    'Browse through the <b>Offers</b> on the Karma Shop and use your Karma Points to enjoy a well deserved treat!</div><br/>\n';

                            }
                        }
                        //wish tab content
                        if (shop_type == 'wish') {
                            if(current_page == 1 && data_type != 'load_more') {
                                ajax_shop_html += '<div class="userDetails ks-details ks-landing" style="margin-top: 0px; margin-bottom: 20px;">You haven’t found what you would like to get on the Karma Shop? \n' +
                                    '\n' +
                                    '<br/><br/>\n' +
                                    '\n' +
                                    'No Problem! You can just create a new <b>User Wish</b> to get what you like!\n' +
                                    '\n' +
                                    '<br/><br/>\n' +
                                    '\n' +
                                    'User Wishes are personal requests of our ParticiPaiders. <b>Fulfilling user wishes</b> is a great way of rewarding others for their contributions to all kind of social projects and keep the good Karma circulating around our Community.</div><br/>\n';
                            }
                        }
                        $.each(data.search_result, function(key, $row){

                            col_md = '4';
                            if(data.auth != 'yes'){
                                col_md = '3';
                            }

                            disabled = '';
                            if($row.status == 'inactive'){
                                disabled = 'disabled-all';
                            }

                            ajax_shop_html += '<div class="col-md-'+col_md+' '+disabled+' shop-needs">'+
                                '<div class="box-004">'+
                                '<div class="corner-badge '+$row.type+'-badge"></div>';

                            display_image = '/img/no_image.jpeg';
                            if($row.profile_picture){
                                display_image = data.needs_media_path+$row.profile_picture;
                            }
                            details_page_link = '';
                            if($row.type == 'need'){
                                details_page_link = '/project/need/'+$row.id;
                            }else{
                                details_page_link = '/user/'+$row.type+'/'+$row.id;
                            }
                            ajax_shop_html += '<a href="'+details_page_link+'"><img class="reight-side-image" src="'+display_image+'"></a>'+
                                '<div class="box-004-lower">'+
                                '<h2 class="shop-box-title">';

                            ajax_shop_html += '<a class="pink-cls two-line-text" href="'+details_page_link+'" title="'+$row.title+'">';
                            if($row.title.length > 45){
                                ajax_shop_html += $row.title.substring(0,45)+'...';
                            }else{
                                ajax_shop_html += $row.title;
                            }
                            ajax_shop_html += '</a>'+
                                '</h2>'+
                                '<div class="box-004-auth">';
                            //                            if($row.type == 'need'){
                            //                                ajax_shop_html +='<a href="user/profile-timeline/'+$row.need_user_id+'">'+$row.need_user_full_name+'</a>';
                            //                            }else{
                            //                                ajax_shop_html +='<a href="user/profile-timeline/'+$row.user_id+'">'+$row.full_name+'</a>';
                            //          '+$row.need_category_name+'                  }
                            ajax_shop_html +='<a class="pink-cls" href="../user/profile-timeline/'+$row.user_id+'">'+$row.full_name+'</a>';

                            ajax_shop_html += '</div>'+
                                '<div class="need-box-bottom">'+
                                '<p>From '+$row.created_at+'<br>Till '+$row.last_date+'<br>'+$row.need_category_name+'</p>'+
                                '<div class="helps-likes"><span>'+$row.points+'</span><br><small>Points</small></div>';
                            if($row.badge){
                                if($row.need_category_name == 'Equipment')
                                    ajax_shop_html += '<div class="helps-likes-badge"><span class="badge1"></span></div>';
                                else if($row.need_category_name == 'Money/Donations')
                                    ajax_shop_html += '<div class="helps-likes-badge"><span class="badge3"></span></div>';
                                else if($row.need_category_name == 'Contributions')
                                    ajax_shop_html += '<div class="helps-likes-badge"><span class="badge4"></span></div>';
                                else if($row.need_category_name == 'Volunteer')
                                    ajax_shop_html += '<div class="helps-likes-badge"><span class="badge2"></span></div>';
                            }
                            ajax_shop_html += '</div>'+
                                '<div class="cb"></div>'+
                                '<div class="post-date">'+
                                //                            '<p>Posted '+moment.utc($row.posted_date, "YYYY-MM-DD HH:mm:ss").fromNow()+'</p>'+
                                '<p>Posted '+moment($row.posted_date, "YYYY-MM-DD HH:mm:ss").from(moment(data.now,"YYYY-MM-DD HH:mm:ss"))+'</p>'+
                                '<a class="pink-cls" href="'+details_page_link+'">More</a>'+
                                '</div>';

                            if(data.auth != 'yes' || data.search_result_participations[$row.id] == 'not_participated'){

                                ajax_shop_html += '<a href="'+details_page_link+'" class="applynowbtn">Apply Now';
                            }else{
                                ajax_shop_html += '<a class="applynowbtn applied">';
                                if(data.search_result_participations[$row.id] == 'pending'){
                                    ajax_shop_html += 'Applied';
                                }else if(data.search_result_participations[$row.id] == 'accepted'){
                                    ajax_shop_html += 'Participated';
                                }
                            }
                            ajax_shop_html += '</a> '+
                                '</div>'+
                                '</div>'+
                                '</div>';
                        });

                        if(data_type == 'load_more'){
                            $('.shop-items-container').append(ajax_shop_html);
                        }else{
                            $('.shop-items-container').html(ajax_shop_html);
                            total_pages = data.total_pages;
                            current_page = 0;
                            $('#load-more-shop').data('total-pages', total_pages);
                            $('#load-more-shop').data('shop-type', shop_type);
                        }

                        current_page++;
                        $('#load-more-shop').data('current-page', current_page);

                        if(current_page >= total_pages){
                            $('#load-more-shop').hide();
                        }else{
                            $('#load-more-shop').show();
                        }

                    }else if(data_type != 'load_more'){
                        $('.shop-items-container').html('');
                        $('#load-more-shop').hide();
                        $('.no-data-found').html('<p class="karma-shop not-found">No Data Found</p>');
                    }

                    $('.animation_image').hide();


                    //                    console.log(data.search_result);

                }
            },
            'json'
        );
        request.error(function(httpObj, textStatus) {
            if(httpObj.status==401)
                window.location = '/user/sign-in';
        });
    });
    //########################################################
    //####                End of Karma Shop               ####
    //########################################################


    //########################################################
    //####                 Send Invitaion                 ####
    //########################################################

    $('#send_invitation_form').on('submit', function(){ 
        
        $('#send-invitation-modal #info-messages').html('<div class="alert alert-warning" role="alert">Please Wait...</div>');
        var name = $( '#send-invitation-modal #name' ).val();
        var email = $( '#send-invitation-modal #email' ).val();
        var reminder = $( '#reminder' ).is(':checked');
        var regex = /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
        if(name == ''){
            $('#send-invitation-modal #info-messages').html('<div class="alert alert-danger" role="alert">Name is required</div>');
        }else if(email == ''){
            $('#send-invitation-modal #info-messages').html('<div class="alert alert-danger" role="alert">Email address is required</div>');
        }else if(!regex.test(email)){
            $('#send-invitation-modal #info-messages').html('<div class="alert alert-danger" role="alert">Invalid email address</div>');
        }else{
            var request = $.post(
                $(this).prop('action'),        
                {
                    "_token": $('#security_token').find( 'input[name=_token]' ).val(),
                    "name": name,
                    "email": email,
                    "reminder": reminder
                },
                function(data){
                    //console.log(data);
                    if(data.status == 'fail'){
                        html = '<div class="alert alert-danger" role="alert">'+data.msg+'</div>';

                        $('#reminder-main').removeClass("dN");
                    }else{
                        //                        $('#send_invitation_form')[0].reset();
                        html = '<div class="alert alert-success" role="alert">'+data.msg+'</div>';
                        $('#reminder-main').addClass("dN");
                        $( '#reminder' ).attr('checked', false);
                    }
                    $('#send-invitation-modal #info-messages').html(html);
                },
                'json'
                );
            request.error(function(httpObj, textStatus) {
                if(httpObj.status==401)
                    window.location = '/user/sign-in';
            });
        }

        //window.location.reload();
        return false;
    });
    $('#send-invitation-link').on('click', function () {
        $('#send-invitation-modal #info-messages').html('');
        $('#send_invitation_form')[0].reset();
    });

    //########################################################
    //####              End of Send Invitaion             ####
    //########################################################


    //########################################################
    //####            Projects Overview                   ####
    //########################################################
    
    $('.get-projects-overview-ajax').on('click', function(){
        
        var type = $(this).data('type'); // need,offer,wish
        var data_type = $(this).data('data-type'); // load_more, new_data

        
        var current_page = '1';
        if(data_type == 'load_more'){
            var total_pages = $(this).data('total-pages');
            current_page = parseInt($(this).data('current-page'), 10);
            $(this).hide(); //hide load more button on click
        }else{
            $('.projects-overview-container-div').html('');
            $('.projects-overview-tabs').removeClass('active');
            $('.projects-overview-tabs.'+type).addClass('active');
        }
        
        
        $('.animation_image').show(); //show loading image
        $('.no-data-found').html('');   
        
        var request = $.post(
            '/projects-overview-ajax', 
            {
                "_token": $('#security_token').find( 'input[name=_token]' ).val(),
                "type": type,
                "current_page": current_page,
                "data_type": data_type
            },
            function(data){
                
                $('.animation_image').hide(); //show loading image
                
                if(data.status == 'success'){
                    
                    if(data.projects.length){
                        
                        var ajax_html = '';
                        $.each(data.projects, function(key, $row){
                            
                            
                            
                            
                            ajax_html += '<div class="needprojectdetail">'+
                            '<div class="needpd-l">'+
                            '<a href="/project/view/'+$row.id+'">'+
                            '<img src="';
                            if($row.project_pic != '' && $row.project_pic != null){
                                ajax_html += data.projects_media_path+$row.project_pic;
                            }else{
                                ajax_html += '/img/no_image.jpeg';
                            }
                            ajax_html += '">'+
                            '</a>'+
                            '</div>'+
                            '<div class="needpd-r">'+
                            '<h3><a title="'+$row.title+'" href="/project/view/'+$row.id+'">';
                        
                            if($row.title.length > 25){
                                ajax_html += $row.title.substring(0,23)+'...';
                            }else{
                                ajax_html += $row.title;
                            }
                            
                            ajax_html += '</a></h3>'+
                            '<p title="'+$row.description+'">';
                        
                            if($row.description.length > 70){
                                ajax_html += $row.description.substring(0,68)+'...';
                            }else{
                                ajax_html += $row.description;
                            }
                            
                            ajax_html += '</p>'+
                            '<div class="statusbar">';
                            status_class = '';
                            if($row.status == 'completed'){
                                status_class = 'completed';
                            }
                            project_percentage = data.project_percentages[$row.id];
                            ajax_html += '<div class="barforcust '+status_class+'" style="width:'+project_percentage+'%"></div>'+
                            '</div>'+
                            '<div class="needpd-rb">'+
                            '<div class="needpd-rb-l">'+project_percentage+'% Complete | '+$row.participants_count+' Participants</div>'+
                            '<div class="needpd-rb-r '+status_class+'">'+
                            '<a href="#">';
                            if($row.status == 'completed'){
                                ajax_html += 'Completed';
                            }else{
                                ajax_html += 'In Progress';
                            }
                            ajax_html += '</a>'+
                            '</div>'+
                            '</div>'+
                            '</div>';
                            if(type != 'own'){    
                                ajax_html += '<div class="needpd-r-r">'+
                                '<p class="heading"><strong>Owned By</strong></p>'+
                                '<div class="owener-img">'+
                                '<a href="/user/profile-timeline/'+$row.user_id+'">'+
                                '<img src="';
                                if($row.user_pic != '' && $row.user_pic != null){
                                    ajax_html += data.users_media_path+$row.user_pic;
                                }else{
                                    ajax_html += '/img/no_profile_image.jpeg';
                                }
                                ajax_html += '">'+
                                '</a>'+
                                '</div>'+
                                '<p class="owener-name">'+
                                '<a href="/user/profile-timeline/'+$row.user_id+'">'+$row.full_name+'</a>'+
                                '</p>'+
                                '</div>';
                            }
                            ajax_html += '<div class="cb"></div>'+
                        '</div>';
                        });
                        
                        if(data_type == 'load_more'){
                            $('.projects-overview-container-div').append(ajax_html);
                        }else{
                            $('.projects-overview-container-div').html(ajax_html);
                            total_pages = data.total_pages;
                            current_page = 0;
                            $('#load-more-projects').data('total-pages', total_pages);
                            $('#load-more-projects').data('type', type);
                        }
                        
                        current_page++;
                        $('#load-more-projects').data('current-page', current_page);
                        
                        if(current_page >= total_pages){
                            $('#load-more-projects').hide();    
                        }else{
                            $('#load-more-projects').show();
                        }
                        
                    }else if(data_type != 'load_more'){
                        $('.projects-overview-container-div').html('');
                        $('#load-more-projects').hide();   
                        $('.no-data-found').html('<p class="karma-shop not-found">No Data Found</p>');
                    }                    
                    $('.animation_image').hide();

                }
            },
            'json'
            );
        request.error(function(httpObj, textStatus) {
            if(httpObj.status==401)
                window.location = '/user/sign-in';
        });
    });
//########################################################
//####              End of Projects OverView          ####
//########################################################
});

///////get post likes user///
function getPostLikesUsers(post_id){
    $('#post-likes-users-modal .scrollable-area').hide();
    $('#post-likes-users-modal').modal({
        keyboard: false
    });
    $('#post-likes-users-modal #plz_wait_message').show();
    jQuery.ajax({
        type: "POST",
        url: "/user/get-users-likes-post",
        data:({
            post_id:post_id
        }),
        dataType: 'json',
        success: function(data){
            //console.log(data);
            html = '';
            if(data.status == 'success'){
                $.each(data.users, function(key, row){
                    if(row.liker_profile_picture != ''){
                        profile_image = data.users_media_path+row.liker_profile_picture;
                    }else{
                        profile_image = '/img/no_profile_image.jpeg';
                    }
                    html +='<li>';

                    html +='<div class="owener-img"><a href="/user/profile-timeline/'+row.liker_id+'"><img title="'+ row.liker_name+'" src="'+profile_image+'"></a></div>';
                    html +='<p class="authr-nam">'+ row.liker_name+'</p>';
                            
                    html +='<div class="cb"></div>';
                    html +='</li>';
                });
                
            }else{
                html +='<li>'+data.msg+'</li>';
            }
            $('#post-likes-users-modal .scrollable-area').show();
            $('#post-likes-users-modal #plz_wait_message').hide();
            $('#post-likes-users-modal #likes-users').html(html);
            
        }
    });
    return false;
}

///////get media likes user///
function getMediaLikesUsers(media_id){
    $('#media-likes-users-modal .scrollable-area').hide();
    $('#media-likes-users-modal').modal({
        keyboard: false
    });
    $('#media-likes-users-modal #plz_wait_message').show();
    jQuery.ajax({
        type: "POST",
        url: "/user/get-users-likes-media",
        data:({
            media_id:media_id
        }),
        dataType: 'json',
        success: function(data){
            //console.log(data);
            html = '';
            if(data.status == 'success'){
                $.each(data.users, function(key, row){
                    if(row.liker_profile_picture != ''){
                        profile_image = data.users_media_path+row.liker_profile_picture;
                    }else{
                        profile_image = '/img/no_profile_image.jpeg';
                    }
                    html +='<li>';

                    html +='<div class="owener-img"><a href="/user/profile-timeline/'+row.liker_id+'"><img title="'+ row.liker_name+'" src="'+profile_image+'"></a></div>';
                    html +='<p class="authr-nam">'+ row.liker_name+'</p>';
                            
                    html +='<div class="cb"></div>';
                    html +='</li>';
                });
                
            }else{
                html +='<li>'+data.msg+'</li>';
            }
            $('#media-likes-users-modal .scrollable-area').show();
            $('#media-likes-users-modal #plz_wait_message').hide();
            $('#media-likes-users-modal #likes-users').html(html);
            
        }
    });
    return false;
}
function searchParticipaid(type){
    $('#search_type').val(type);
    $('#search-participaid').click();
}
function globalSearchParticipaid(type){
    $('#global_search_type').val(type);
    $('#global-search-participaid').click();
}

//#################################################################
//###########          Get Notifications Ajax           ###########
//#################################################################
 
$(document).on('click', '#load-more-notifications', function(){
     
    var element = this;
    var container_type = $(this).data('container-type'); // today, yesterday, month
    var container_date = $(this).data('container-date'); // today, yesterday, month
    var total_pages = $(this).data('total-pages');
    var current_page = parseInt($(this).data('current-page'), 10);
    $(this).hide(); //hide load more button on click
    $('.animation_image').show(); //show loading image
        
    var request = $.post(
        '/get-notifications-ajax', 
        {
            "_token": $('#security_token').find( 'input[name=_token]' ).val(),
            "container_type": container_type,
            "container_date": container_date,
            "current_page": current_page
        },
        function(data){
                
            $('.animation_image').hide(); //show loading image
                
            if(data.status == 'success'){
                if(data.notifications.length){
                    $('#load-more-notifications').before(data.notifications);
                    current_page++;
                    $('#load-more-notifications').data('current-page', current_page);
                    $('#load-more-notifications').data('container-type', data.container_type);
                    $('#load-more-notifications').data('container-date', data.container_date);
                    console.log(current_page);
                    console.log(total_pages);
                    if(current_page >= total_pages){
                        $('#load-more-notifications').hide();    
                    }else{
                        $('#load-more-notifications').show();
                    }   
                }
            }
        },
        'json'
        );
    request.error(function(httpObj, textStatus) {
        if(httpObj.status==401)
            window.location = '/user/sign-in';
    });
});
//#################################################################
//#####            End Of Get Notifications Ajax        ###########
//#################################################################


//#################################################################
//###########              Subscription                 ###########
//#################################################################

$(document).on('click' , '#subscription_btn', function(){
        
    $('#subscription_form').submit();
});
    
$(document).on('submit', '#subscription_form', function(){ 
        
    var email = $( '#subscription_form #subscription_email' ).val();
    var regex = /\S+@\S+\.\S+/;
    var valid = true;
    if(email == ''){
        $('#subscription-response-modal .modal-body').html('<div id="info_messages" class="alert alert-danger" role="alert">Email address is required</div>');
        valid = false;
    }else if(!regex.test(email)){
            
        $('#subscription-response-modal .modal-body').html('<div id="info_messages" class="alert alert-danger" role="alert">Invalid email address</div>');
        valid = false;
    }else{
        $('#subscription-response-modal .modal-body').html('<div id="info_messages" class="alert alert-warning" role="alert">Please wait...</div>');    
    }
        
    $('#subscription-response-modal').modal({
        keyboard: false 
    });
        
    if(valid){
      var request =  $.post(
            $(this).prop('action'),        
            {
                "_token": $('#security_token').find( 'input[name=_token]' ).val(),
                "email": email
            },
            function(data){
                if(data.status == 'fail'){
                    html = '<div id="info_messages" class="alert alert-danger" role="alert">'+data.msg+'</div>';
                }else{
                    $( '#subscription_form #subscription_email' ).val('');    
                    html = '<div id="info_messages" class="alert alert-success" role="alert">'+data.msg+'</div>';
                        
                }
                $('#subscription-response-modal .modal-body').html(html);
            },
            'json'
            ); 
    }
    request.error(function(httpObj, textStatus) {
        if(httpObj.status==401)
            window.location = '/user/sign-in';
    });
    return false;
});

//#################################################################
//########             End of Subscription              ###########
//#################################################################


//#################################################################
//########                    FAQ                       ###########
//#################################################################

$(document).on('click', '.faq-question', function(){
    var faq_id = $(this).data('faq-id'); 
    $('.faq-question.'+faq_id).toggleClass('yes');
    $('.faq-answer.'+faq_id).toggleClass('active').slideToggle();
});

//#################################################################
//########              End of FAQ                      ###########
//#################################################################

function getDashboardComments(type,item_id){
    jQuery.ajax({
        type: "POST",
        url: "/user/get-dashboard-comments",
        data:({
            item_id:item_id,
            type: type
        }),
        dataType: 'json',
        success: function(data){
            if(data.status == 'success'){
                $('#showComments_'+item_id).html(data.html);
                $('#showAllCommentsBarId'+item_id).hide();
            }
            
            
        }
    });
    return false;
    
}

$.ajaxSetup({
    beforeSend: function() {

    },
    complete: function() {
        var imgs = $("img");
        for(i =0 ; i < imgs.length; i++){
            if(imgs[i].height < imgs[i].width){
                $("img").addClass('landscape');
            }
        }
    }
});



/*
* Read more function for reward points
* */
$(document).ready(function() {
    // Configure/customize these variables.
    var showChar = 60;  // How many characters are shown by default
    var ellipsestext = "...";
    var moretext = "show more";
    var lesstext = "show less";


    $('.readmore').each(function() {
        var content = $(this).html();

        if(content.length > showChar) {

            var c = content.substr(0, showChar);
            var h = content.substr(showChar, content.length - showChar);

            var html = c + '<span class="moreellipses">' + ellipsestext+ '&nbsp;</span><span class="morecontent"><span>' + h + '</span>&nbsp;&nbsp;<a href="" class="morelink">' + moretext + '</a></span>';

            $(this).html(html);
        }

    });

    $(".morelink").click(function(){
        if($(this).hasClass("less")) {
            $(this).removeClass("less");
            $(this).html(moretext);
        } else {
            $(this).addClass("less");
            $(this).html(lesstext);
        }
        $(this).parent().prev().toggle("slow");
        $(this).prev().toggle("slow");
        return false;
    });
});
// youtube preview image click event
$(document).ready(function(){


    $('.image').click(function(){
        $(this).css('display' , 'none');
        $(this).next().css('display' , 'block');
        var videoURL = $(this).next().prop('src');
        videoURL += "&autoplay=1";
        $(this).next().prop('src',videoURL);

        var player_id = $(this).next().attr('id');
        if(players[player_id].getPlayerState() == 3 || players[player_id].getPlayerState() == 1 || players[player_id].getPlayerState() == 5) {
            //stop the auto scrolling
            console.log("stop slider video " + player_id);
            $("#myCarousel").carousel("pause");
        }

        if(players[player_id].getPlayerState() == 0 || players[player_id].getPlayerState() == 2) {
            //start auto scrolling.
            console.log("start slider " + player_id);
//                         $("#myCarousel").carousel("next");
            $("#myCarousel").carousel("cycle");
        }
    })
});
$( document ).ready(function() {
    
    // participate in a need
    $('#need-apply-now-modal #apply-now').on('click', function(){ 

        //        console.log($(this).data('other-user-id'));
        // ajax post method to pass form data to the 
        
        var need_id = $(this).data('need-id');
        var message_content = $('#need-apply-now-modal #message_content').val();
        if(message_content != ''){
            $('#need-apply-now-modal #message_content').val('');
            $.post(
                $(this).data('url'), 
                {
                    "_token": $('#security_token').find( 'input[name=_token]' ).val(),
                    "need_id": need_id,
                    "reference_id": $(this).data('reference-id'),
                    "type": $(this).data('type'),
                    "content": message_content
                },
                function(data){
                    
                    if(data.status == 'success'){  
                        $('#need-apply-now-modal #info-messages').html('<div class="alert alert-success" role="alert">'+data.msg+'</div>');
                        $('#need-apply-now-modal #apply-now').remove();
                        
                        $('.applynowbtn.'+need_id).html('Applied').addClass('applied').removeClass('cursor-pointer').removeAttr('href data-toggle data-target');
                    }else {
                        $('#need-apply-now-modal #info-messages').html('<div class="alert alert-danger" role="alert">'+data.msg+'</div>');    
                    }
                },
                'json'
                );
        }else{
            $('#need-apply-now-modal #info-messages').html('<div class="alert alert-danger" role="alert">Please enter some message</div>');
        }
         
       
        return false;
    });
    
    $('#need-apply-now-modal .close-modal').on('click', function () {
        $('#need-apply-now-modal #message_content').val('');
        $('#need-apply-now-modal #info-messages').html('');
    });
    
    // accept a participation request in a need
    $('.change_request_status').on('click', function(){ 

        var request_id = $(this).attr('request-id');
        var status = $(this).data('status');
        $.post(
            $(this).data('url'), 
            {
                "_token": $('#security_token').find( 'input[name=_token]' ).val(),
                "participation_id": request_id,
                "participation_status": status
            },
            function(data){
                if(data.status == 'success'){
                    if(status == 'accepted'){
                        $('.request_status_btn.'+request_id).html('<a class="btn-accepted">Accepted</a>');
                    }else if(status == 'rejected'){
                        $('.request_status_btn.'+request_id).html('<a class="btn-rejected">Rejected</a>');
                    }else if(status == 'cancelled'){
                        $('.request_status_btn.'+request_id).html('');
                        $('.sent_status_'+request_id).html('Cancelled');   
                        $('#cancel-request-modal').modal('toggle');
                    }
                }
            },
            'json'
            ); 
       
        return false;
    });
    
    $('.received-box-cont .cancel-request-initaite').on('click', function () {
        var request_id = $(this).attr('request-id');
        $('#cancel-request-modal').modal({
            keyboard: false
        });
        $('#cancel-request-modal #cancel-request-mdl-btn').attr('request-id', request_id);
    });
    
    $('.cancel-request-modal-close').on('click', function () {
        $('#cancel-request-modal #cancel-request-mdl-btn').attr('request-id', '');
    });
});
$( document ).ready(function() {

    // Delete Project Gallery Image
    $( document ).on( "click", ".delete_project_gallery_image", function(e) {
        
        var image_id = $(this).data('image-id');
        jQuery.ajax({
            type: "POST",
            url: $(this).data('delete-url'),
            data:({
                "_token" : $( 'input[name=_token]' ).val(),
                "image_name" : $(this).data('image-name'),
                "project_id" : $(this).data('project-id'),
                "request_type" : 'delete'
            }),
            dataType: 'json',
            success: function(data){
                if(data.status == 'success'){
                    $("#image-"+image_id).remove(); 
                }else{
                   alert(data.msg);
                }
            }
        });
        return false;
    });
    
    // Add Description of Project Gallery Image
    $( document ).on( "click", ".project_gallery_image_description", function(e) {
        
        var image_id = $(this).data('image-id');
        jQuery.ajax({
            type: "POST",
            url: $(this).data('update-url'),
            data:({
                "_token" : $( 'input[name=_token]' ).val(),
                "image_id" : $(this).data('image-id'),
                "image_description" : $('#description-image-'+image_id).val(),
                "project_id" : $(this).data('project-id'),
                "request_type" : 'update'
            }),
            dataType: 'json',
            success: function(data){
                
            }
        });
        return false;
    });
    
});

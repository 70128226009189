$( document ).ready(function() {
    

    selected_account_type = $('#register input:radio[name=account_type]:checked').val();
    if(selected_account_type == 'individual'){
//        $('#fullname_field').show();
        $('#first_name_field').show();
        $('#last_name_field').show();
        $('#terms_confirmation').show();
            
        $('#company_name_field').hide();
        $('#contact_name_field').hide();
        $('#certification_confirmation').hide();
        $('#acknowledge_confirmation').hide();
        $('#register_with_facebook').show();
        if($('#facebook_id').val() != ''){
         //   $('#email_field').hide();
            $('#password_field').hide();
            $('#register_with_facebook').hide();
        }
        
    }else{
        $('#company_name_field').show();
        $('#contact_name_field').show();
        $('#certification_confirmation').show();
        $('#acknowledge_confirmation').show();
            
//        $('#fullname_field').hide();
        $('#first_name_field').hide();
        $('#last_name_field').hide();
        $('#terms_confirmation').hide();
        
        $('#register_with_facebook').hide();
        
    }

    
    
    $("#register input:radio").change(function () {
        if ($(this).val() == "individual") {
            // Disable your roomnumber element here
//            $('#fullname_field').show();
            $('#first_name_field').show();
            $('#last_name_field').show();
            $('#terms_confirmation').show();
            
            $('#company_name_field').hide();
            $('#contact_name_field').hide();
            $('#certification_confirmation').hide();
            $('#acknowledge_confirmation').hide();
            
            $('#register_with_facebook').show();
        } else {
            // Re-enable here I guess
            //alert('organization');
            $('#company_name_field').show();
            $('#contact_name_field').show();
            $('#certification_confirmation').show();
            $('#acknowledge_confirmation').show();
            
//            $('#fullname_field').hide();
            $('#first_name_field').hide();
            $('#last_name_field').hide();
            $('#terms_confirmation').hide();
            
            $('#register_with_facebook').hide();
        }
    });

});
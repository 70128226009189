$( document ).ready(function() {
    
    // for add security question
    $('#update_security_form').on('submit', function(){ 
				 
        // ajax post method to pass form data to the 
        $.post(
            $(this).prop('action'),        {
                "_token": $( this ).find( 'input[name=_token]' ).val(),
                "question": $( '#question' ).val(),
                "answer": $( '#answer' ).val()
            },
            function(data){
                //console.log(data);
                if(data.status != ''){
                    show_account_preferences_massage_dialog(data.msg);
                    
                    if(data.status == 'success'){
                        //$('#update_security_form')[0].reset();
                    }

                }
            //response after the process. 
            },
            'json'
            ); 
       
        return false;
    }); 
    // for update password
    $('#update_password_form').on('submit', function(){ 
				 
        // ajax post method to pass form data to the 
        $.post(
            $(this).prop('action'),        {
                "_token": $( this ).find( 'input[name=_token]' ).val(),
                "current_password": $( '#current_password' ).val(),
                "password": $( '#password' ).val(),
                "password_again": $( '#password_again' ).val()
            },
            function(data){
                //console.log(data);
                if(data.status != ''){
                    show_account_preferences_massage_dialog(data.msg);
                    
                    if(data.status == 'success')
                        $('#update_password_form')[0].reset();
                }
            //response after the process. 
            },
            'json'
            ); 
       
        return false;
    }); 
//    // for update email
//    $('#update_email_form').on('submit', function(){ 
//				 
//        // ajax post method to pass form data to the 
//        $.post(
//            $(this).prop('action'),        {
//                "_token": $( this ).find( 'input[name=_token]' ).val(),
//                "current_email": $( '#current_email' ).val(),
//                "email": $( '#email' ).val(),
//                "email_again": $( '#email_again' ).val()
//            },
//            function(data){
//                //console.log(data);
//                if(data.status != ''){
//                    show_account_preferences_massage_dialog(data.msg);
//                    
//                    if(data.status == 'success')
//                        $('#update_email_form')[0].reset();
//                    
//                }
//            //response after the process. 
//            },
//            'json'
//            ); 
//       
//        return false;
//    }); 
    
    // for deactivate account
//    $('#deactivate_form').on('submit', function(){ 
//				 
//        // ajax post method to pass form data to the 
//        $.post(
//            $(this).prop('action'),        {
//                "_token": $( this ).find( 'input[name=_token]' ).val(),
//                "current_password": $( '#deactivate_current_password' ).val()
//            },
//            function(data){
//                //console.log(data);
//                if(data.status != ''){
//                    show_account_preferences_massage_dialog(data.msg);
//                    
//                    if(data.status == 'success'){
//                        $('#deactivate_form')[0].reset();
//                        window.location = '/'; 
//                    }
//                       
//                }
//            //response after the process. 
//            },
//            'json'
//            ); 
//       
//        return false;
//    });
});

function show_account_preferences_massage_dialog(message){
    
    $('#account-prefernces-messages-modal #msg-div').html(message);
    $('#account-prefernces-messages-modal').modal({
        keyboard: false
    });
}
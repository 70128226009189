$( document ).ready(function() {
    buttonClicked = false;
    $( document ).on( "focusout", ".edit-comments", function(e){
        //alert('ddddddddd');
        if(!(buttonClicked)){
            // do normal focusout code.
            $('.edit-comment-div').hide();
            $('.only_comment_text').show();
        }else{
            // button was clicked - dont execute some code.
            buttonClicked = false;
        }
    //console.log(e.currentTarget.children);

    });
    $( document ).on( "mousedown", ".edit_comment_form", function(e){

        buttonClicked = true;
    });
    var attachmentsUserPostCountProfile = 0;

    // send user wall post attachments
    $('#wall_post_form #add_attachments').on('click', function(){
        attachmentsUserPostCountProfile++;
        $('#wall_post_form').append('<input class="attachment file-'+attachmentsUserPostCountProfile+'" id="attachment-'+attachmentsUserPostCountProfile+'" name="file[]" type="file" />');
        $('#wall_post_form #attachment-'+attachmentsUserPostCountProfile).click();
        $('#wall_post_form #attachment-'+attachmentsUserPostCountProfile).css('visibility', 'hidden');

    });
    $('body').on('change', '#wall_post_form .attachment', function() {

        if (this.files && this.files[0]) {
            files = this.files[0];
            var reader = new FileReader();
            reader.onload = previewUserPostAttachment;
            reader.readAsDataURL(this.files[0]);
        }
    });
    // To Preview Image
    function previewUserPostAttachment(e) {

        var file_extention = e.target.result.split(",")[0].split(":")[1].split(";")[0].split("/")[1];
        //console.log(file_extention);
        if(file_extention == 'png' || file_extention == 'jpeg' || file_extention == 'jpg'){

            attachment_html = '<div class="file-'+attachmentsUserPostCountProfile+' media-list-box">'+
            '<img style="height:50px; width:50px" src="'+ e.target.result +'"/>'+
            '<a class="cursor-pointer" onclick="$(\'.file-'+attachmentsUserPostCountProfile+'\').remove();">x</a>'+
            '</div>';
        }else if(file_extention == 'pdf'){
            attachment_html = '<div class="file-'+attachmentsUserPostCountProfile+' media-list-box">'+
            '<img style="height:50px; width:50px" src="/img/pdf.png" title="'+$('.file-'+attachmentsUserPostCountProfile).val()+'"/>'+
            '<a class="cursor-pointer" onclick="$(\'.file-'+attachmentsUserPostCountProfile+'\').remove();">x</a>'+
            '</div>';
        }else if(file_extention == 'vnd.openxmlformats-officedocument.presentationml.presentation'){
            attachment_html = '<div class="file-'+attachmentsUserPostCountProfile+' media-list-box">'+
            '<img style="height:50px; width:50px" src="/img/ppt.png" title="'+$('.file-'+attachmentsUserPostCountProfile).val()+'"/>'+
            '<a class="cursor-pointer" onclick="$(\'.file-'+attachmentsUserPostCountProfile+'\').remove();">x</a>'+
            '</div>';
        }else if(file_extention == 'vnd.openxmlformats-officedocument.wordprocessingml.document' || file_extention == 'docx'){
            attachment_html = '<div class="file-'+attachmentsUserPostCountProfile+' media-list-box">'+
            '<img style="height:50px; width:50px" src="/img/doc.png" title="'+$('.file-'+attachmentsUserPostCountProfile).val()+'"/>'+
            '<a class="cursor-pointer" onclick="$(\'.file-'+attachmentsUserPostCountProfile+'\').remove();">x</a>'+
            '</div>';
        }else if(file_extention == 'zip'){
            attachment_html = '<div class="file-'+attachmentsUserPostCountProfile+' media-list-box">'+
            '<img style="height:50px; width:50px" src="/img/zip.png" title="'+$('.file-'+attachmentsUserPostCountProfile).val()+'"/>'+
            '<a class="cursor-pointer" onclick="$(\'.file-'+attachmentsUserPostCountProfile+'\').remove();">x</a>'+
            '</div>';
        }else if(file_extention == 'vnd.ms-excel' || file_extention == 'vnd.openxmlformats-officedocument.spreadsheetml.sheet'){
            attachment_html = '<div class="file-'+attachmentsUserPostCountProfile+' media-list-box">'+
            '<img style="height:50px; width:50px" src="/img/xls.png" title="'+$('.file-'+attachmentsUserPostCountProfile).val()+'"/>'+
            '<a class="cursor-pointer" onclick="$(\'.file-'+attachmentsUserPostCountProfile+'\').remove();">x</a>'+
            '</div>';
        }else if(file_extention == 'plain'){
            attachment_html = '<div class="file-'+attachmentsUserPostCountProfile+' media-list-box">'+
            '<img style="height:50px; width:50px" src="/img/txt.png" title="'+$('.file-'+attachmentsUserPostCountProfile).val()+'"/>'+
            '<a class="cursor-pointer" onclick="$(\'.file-'+attachmentsUserPostCountProfile+'\').remove();">x</a>'+
            '</div>';
        }else{
            attachment_html = '<div class="file-'+attachmentsUserPostCountProfile+' media-list-box">'+
            '<img style="height:50px; width:50px" src="/img/generic.png" title="'+$('.file-'+attachmentsUserPostCountProfile).val()+'"/>'+
            '<a class="cursor-pointer" onclick="$(\'.file-'+attachmentsUserPostCountProfile+'\').remove();">x</a>'+
            '</div>';
        }

        $('#wall_post_form .media-list').append(attachment_html);
    };


    // for adding need/offer/wish comments
    $( document ).on( "click", ".need_offer_comment_form", function(e){
        e.stopPropagation(); // Stop stuff happening
        e.preventDefault();
        comment_textarea_id = e.currentTarget.id;
        //console.log(e.currentTarget);
        addNeedOfferComments(comment_textarea_id);
        return false;
    });
    $( document ).on( "keypress", ".need_offer_comments", function(e) {
        if (e.which == 13) {
            comment_textarea_id = e.currentTarget.id;
            addNeedOfferComments(comment_textarea_id);
            return false;
        }
    // alert( "Goodbye!" ); // jQuery 1.7+
    })

    // for adding comments
    $( document ).on( "click", ".post_comment_form", function(e){
        var auth_user = $('#auth-user').val();
        if(auth_user == '') {
            window.location = '/user/sign-in';
        }
        else {
            e.stopPropagation(); // Stop stuff happening
            e.preventDefault();
            comment_textarea_id = e.currentTarget.id;
            //console.log(e.currentTarget);
            addPostComments(comment_textarea_id);
            return false;
        }
    });
    $( document ).on( "keypress", ".comments", function(e) {
        if (e.which == 13) {
            comment_textarea_id = e.currentTarget.id;
            addPostComments(comment_textarea_id);
            return false;
        }
    // alert( "Goodbye!" ); // jQuery 1.7+
    });

    $( document ).on( "click", ".user_post_btn", function(e){
        var auth_user = $('#auth-user').val();
        if(auth_user == '') {
            window.location = '/user/sign-in';
        }
        else{
            e.stopPropagation(); // Stop stuff happening
            e.preventDefault();
            $('form#wall_post_form').submit();
            return false;
            }
    });

    $('#post').keypress(function (e) {

        if (e.which == 13) {

            e.stopPropagation(); // Stop stuff happening
            e.preventDefault();
            $('form#wall_post_form').submit();
            return false;    //<---- Add this line
        }
    });
    // for add security question
    $('#wall_post_form').on('submit', function(){
        //console.log(files);

        if($('textarea#post').val() != ''){
            $('#post-wait').show();
            $("textarea#post").blur();
            var form = document.getElementById('wall_post_form');

            var formData = new FormData(form);
            if(files){
                $.each(files, function(key, value)
                {
                    formData.append(key, value);

                });
            }

            //console.log(formData);
            var xhr = new XMLHttpRequest();
            // Add any event handlers here...
            xhr.open('POST', form.getAttribute('action'), true);
            xhr.responseType = "text";
            xhr.send(formData);
            xhr.onload = function(e) {

                //console.log(xhr.response);
                transferComplete(xhr);
            /* ... */
            }
            //xhr.addEventListener("load", transferComplete, false);

            return false; // To avoid actual submission of the form

        }

        return false;
    });

    function transferComplete(xhr){
        //console.log(data);
        var data = xhr.response;  // not responseText
        data = JSON.parse( data );
      //  console.log(data);
        action = $('#load_more_action').val();
        if(data.status == 'fail'){
            $('#error_messages').html(data.msg);
        }
        else if(data.status == 'success'){
            $('#error_messages').html('');
            attachment = data.result_data.attachment;
            attachment_html = '';
            if(attachment != ''){
                if(action == 'dashboard'){
                    image_class= 'dashboard-post-image';
                }else{
                    image_class= 'wall-post-image';
                }

                attachment_array = attachment.split(',');
                //console.log(attachment_array);
                jQuery.each(attachment_array, function(index, item) {
                    // do something with `item` (or `this` is also `item` if you like)
                    file_type = item.split('.');
                    if(file_type[1] == 'png' || file_type[1] == 'jpg' || file_type[1] == 'jpeg'){
                        attachment_html += '<img class="'+image_class+'" src="/'+upload_user_posts_path+'images/'+item+'">';

                    }else{
                        attachment_html += '<a target= "_blank" download href="/'+upload_user_posts_path+'files/'+item+'"><img src="/img/generic-big.png" title="'+item+'"></a>';
                    }

                });
            }
            $('textarea#post').val("");
            html  = '';


            //alert(action);
            if(action == 'dashboard'){



                html += '<div class="a-home-feed user-comment" id="post_'+ data.result_data.post_id +'">';
                html += '<div class="a-feed-top">';

                html += '<a href="javascript:void(0);">';
                console.log(data.result_data.login_user_data.profile_picture);
                if(data.result_data.login_user_data.profile_picture == ''){
                    html += '<img src="/img/no_profile_image.jpeg">';
                }else{
                    html += '<img src="/'+users+'images/'+data.result_data.login_user_data.profile_picture+'">';
                }
                html += '</a>';
                if(data.result_data.login_user_data.type == 'individual'){
                    if(data.result_data.gender == 'female'){
                        html += '<p><a href="/user/profile-timeline/'+data.result_data.login_user_data.id+'">'+data.result_data.login_user_data.full_name+'</a> posted on her own <a href="/user/profile-timeline/'+data.result_data.login_user_data.id+'">timeline</a> just now</p>';
                    }
                    else{
                        html += '<p><a href="/user/profile-timeline/'+data.result_data.login_user_data.id+'">'+data.result_data.login_user_data.full_name+'</a> posted on his own <a href="/user/profile-timeline/'+data.result_data.login_user_data.id+'">timeline</a> just now</p>';
                    }
                }else{
                    html += '<p><a href="/user/profile-timeline/'+data.result_data.login_user_data.id+'">'+data.result_data.login_user_data.full_name+'</a> posted on its own <a href="/user/profile-timeline/'+data.result_data.login_user_data.id+'">timeline</a> just now</p>';
                }


                html += '<div class="cb"></div>';
                html += '</div>';
                html += '<div class="a-feed-mid">';
                html += '<p>'+data.result_data.post_text+'</p>'+attachment_html;

                html += '</div>';
                html += '<div class="comm-lower-btns a-feed-low">';
                html += '<div class="left-btns">';
                html += '<span id="post_count_'+data.result_data.post_id+'"><a class="heart" href="javascript:void(0);" onclick="likeOrUnlikePost('+data.result_data.post_id+');" title="Like"></a><span class="likes-count-popup">0</span></span>';
                html += '<span id="post_comments_count_'+data.result_data.post_id+'"><a class="balon" href="#"></a>0</span>';
                html += '</div>';
                html += '<div class="right-btns">';

                html += '<p>Added '+data.result_data.post_time+'</p>';
                html += '</div>';
                html += '</div>';

                html += '<div class="a-commented-2">';
                html += '<div id="showComments_'+data.result_data.post_id+'">';

                html += '</div>';
                html += '<div class="add-a-comment">';
                html += '<form id="form_post_comment_'+data.result_data.post_id+'" enctype="multipart/form-data" name="post_comment_form" accept-charset="UTF-8" action="/user/add-post-comment" method="POST">';
                html += '<input type="hidden" value="'+data.result_data.csrf_token+'" name="_token">';
                html += '<input type="text" id="post_comment_'+data.result_data.post_id+'" class="comments" name="comment" placeholder="add your comments" ></textarea>';
                html += '<button type="button" id="post_comment_'+data.result_data.post_id+'" class="post_comment_form">Submit</button>';
                html += '</form>';
                html += '</div>';
                html += '</div>';

                html += '</div>';




            }else{
                html += '<div class="user-comment" id="post_'+ data.result_data.post_id +'">';
                if(data.result_data.login_user_data.profile_picture == null || data.result_data.login_user_data.profile_picture == ''){
                    html += '<img src="/img/no_profile_image.jpeg">';
                }else{
                    html += '<a class="profile-img-holder">'
                       + '<img  src="/'+users+'images/'+data.result_data.login_user_data.profile_picture+'" class="landscape"></a>'
                       ;
                }


                html += '<div class="comment-container">';
                html += '<div class="user-comm-content">';
                html += '<p>'+data.result_data.post_text+'</p>'+attachment_html;
                html += '<div class="cb"></div>';
                html += '<div class="comm-lower-btns">';

                html += '<div class="left-btns">';
                html += '<span id="post_count_'+data.result_data.post_id+'"><a class="heart" href="javascript:void(0);" onclick="likeOrUnlikePost('+data.result_data.post_id+');" title="Like"></a><span class="likes-count-popup">0</span></span>';
                html += '<span id="post_comments_count_'+data.result_data.post_id+'"><a class="balon" href="#"></a>0</span>';
                html += '</div>';

                html += '<div class="right-btns">';
                html += '<a class="del" href="javascript:void(0);" onclick="openDeletePostConfirmationPopup('+data.result_data.post_id+');"></a>';
                html += '<p>Added '+data.result_data.post_time+'</p>';
                html += '</div>';

                html += '</div>';

                html += '</div>';
                html += '<div class="cb"></div>';
                html += '<div class="a-commented">';

                html += '<div id="showComments_'+data.result_data.post_id+'">';

                html += '</div>';
                html += '<div class="add-a-comment">';
                html += '<form id="form_post_comment_'+data.result_data.post_id+'" enctype="multipart/form-data" name="post_comment_form" accept-charset="UTF-8" action="/user/add-post-comment" method="POST">';
                html += '<input type="hidden" value="'+data.result_data.csrf_token+'" name="_token">';
                html += '<input type="text" id="post_comment_'+data.result_data.post_id+'" class="comments" name="comment" placeholder="add your comments" ></textarea>';
                html += '<button type="button" id="post_comment_'+data.result_data.post_id+'" class="post_comment_form">Submit</button>';
                html += '</form>';
                html += '</div>';
                html += '</div>';
                html += '</div>';
                html += '<div class="cb"></div>';
                html += '</div>';
            }

            $('#all_wall_posts').prepend(html);
            $(".removeFilesClass").remove();
            $(".abcd").remove();
            $(".filedivClass").remove();
            $('#wall_post_form .media-list').html('');
            $('#wall_post_form .attachment').remove();

        }
        $('#post-wait').hide();
    }

    // for editing comments
    $( document ).on( "click", ".edit_comment_form", function(e){
        e.stopPropagation(); // Stop stuff happening
        e.preventDefault();
        comment_textarea_id = e.currentTarget.id;
        //alert(comment_textarea_id);
        //console.log(e.currentTarget);
        editCommentPost(comment_textarea_id);
        return false;
    });
    $( document ).on( "keypress", ".edit-comments", function(e) {
        if (e.which == 13) {
            comment_textarea_id = e.currentTarget.id;
            //alert(comment_textarea_id);
            editCommentPost(comment_textarea_id);
            return false;
        }
    // alert( "Goodbye!" ); // jQuery 1.7+
    });


});

function editComment(comment_id){
    $('#edit-comment_'+comment_id).hide();
    $('#edit-comments-form_'+comment_id).show();
    $('#edit_comment_'+comment_id).focus();
    comment_text = $('#edit-comment_'+comment_id).text();
    $('#edit_comment_'+comment_id).val(comment_text);


}


function editCommentPost(comment_textarea_id){
    //alert(post_id);
    comment_textarea = comment_textarea_id.split('_');
    comment_id = comment_textarea[2];
    comment_text = $('#edit_comment_'+comment_id).val();
    // alert(comment_text);
    if(comment_text != ''){


        jQuery.ajax({
            type: "POST",
            url: "/user/edit-comment",
            data:({
                _token: $('#edit_comment_form'+comment_id).find( 'input[name=_token]' ).val(),
                comment_text: comment_text,
                comment_id:comment_id
            }),
            dataType: 'json',
            success: function(data){
                //console.log(data);
                if(data.status == 'success'){
                    $('#edit-comments-form_'+comment_id).hide();
                    $('#edit-comment_'+comment_id).html(data.comment_text);
                    $('#edit-comment_'+comment_id).show();
                }

            }
        });
    }
    return false;

}


function openDeletePostConfirmationPopup(post_id){
    $('#delete-post-confirmation-modal').modal({
        keyboard: false
    });
    $('#delete_post_confirm_btn').attr('onclick','deletePost('+post_id+')');
    return false;
}

function deletePost(post_id){
    //alert(post_id);

    jQuery.ajax({
        type: "POST",
        url: "/user/delete-wall-post",
        data:({
            post_id:post_id
        }),
        dataType: 'json',
        success: function(data){
            //console.log(data);
            if(data.status == 'success'){
                $("#post_"+post_id).remove();
            }else{
                $('#error_messages').html(data.msg);
            }
            $('#delete-post-confirmation-modal').modal('hide')


        }
    });
    return false;

}



function addNeedOfferComments(comment_textarea_id){

    item_reference = comment_textarea_id.split('_');
    item_id = item_reference[3];
    //alert(item_id);
    //alert(wall_post_id);
    comment_text = $('#'+comment_textarea_id).val();
    //alert(comment_text);
    if(comment_text != ''){
        $('#'+comment_textarea_id).val('');
        //alert($('#form_'+comment_textarea_id ).find( 'input[name=_token]' ).val());
        jQuery.ajax({
            type: "POST",
            url: "/need-offer/add-comment",
            data:({
                _token: $('#form_'+comment_textarea_id ).find( 'input[name=_token]' ).val(),
                comment_text: comment_text,
                item_id: item_id
            }),
            dataType: 'json',
            success: function(data){
                //console.log(data);
                if(data.status == 'success'){
                    var comment_html = '<div class="comment-from comment-fixed-height" id="comment_'+data.new_comment_id+'">'+
                    '<img  src="'+ $('#auth-user-profile-picture').attr('src') +'">'+
                    '<p>'+
                    '<span class="only_comment_text" id="comment_text_'+data.new_comment_id+'">'+comment_text+'</span> '+
                    '<a class="delete-comment cursor-pointer" data-delete-url="/need-offer/delete-comment" data-comment-id="'+data.new_comment_id+'"></a> '+
                    '<a class="edit-comment edit-need-cmnt cursor-pointer" data-comment-id="'+data.new_comment_id+'"></a> '+
                    '<div id="edit_need_comment_div_'+data.new_comment_id+'" class="edit-comment-div" style="display:none">'+
                    '<input class="edit_need_comment_text" id="new_need_comment_'+data.new_comment_id+'" type="text" value="'+comment_text+'" name="new_need_comment" data-comment-id="'+data.new_comment_id+'">'+
                    '<button id="edit_need_comment_btn_'+data.new_comment_id+'" class="edit_need_comment_btn" type="button" data-edit-url="/need-offer/edit-comment" data-comment-id="'+data.new_comment_id+'">Update</button>'+
                    '</div>'+
                    '</p>'+
                    '</div>';
                    $('#showComments_'+item_id).append(comment_html);
                //                    post_comments_count_html = '<a class="balon cursor-pointer"></a>'+data.result_data.comments_count
                //                    $('#post_comments_count_'+wall_post_id).html(post_comments_count_html);
                }else{
                    $('#need-comment-response-modal .modal-body').html('<div id="info_messages" class="alert alert-danger" role="alert">'+ data.msg +'</div>');
                    $('#need-comment-response-modal').modal({
                        keyboard: false
                    })
                }


            }
        });
    }
    return false;

}

function addPostComments(comment_textarea_id){
    //alert(post_id);
    wall_post = comment_textarea_id.split('_');
    wall_post_id = wall_post[2];
    //alert(wall_post_id);
    comment_text = $('#'+comment_textarea_id).val();
    //alert(comment_text);
    $('#'+comment_textarea_id).val('');
    //alert($('#form_'+comment_textarea_id ).find( 'input[name=_token]' ).val());
    jQuery.ajax({
        type: "POST",
        url: "/user/add-post-comment",
        data:({
            _token: $('#form_'+comment_textarea_id ).find( 'input[name=_token]' ).val(),
            comment_text: comment_text,
            wall_post_id: wall_post_id
        }),
        dataType: 'json',
        success: function(data){
            //console.log(data);
            if(data.status == 'success'){
                $('#'+comment_textarea_id).val("");
                html = '';
                html += '<div class="comment-from comment-fixed-height" id="comment_'+data.result_data.post_comment_id+'">';
                //alert(data.result_data.login_user_data.file_name);
                if(data.result_data.login_user_data.profile_picture == null || data.result_data.login_user_data.profile_picture == ''){

                    html += '<img src="/img/no_profile_image.jpeg">';
                }else{
                    html += '<img src="/'+users+'images/'+data.result_data.login_user_data.profile_picture+'">';

                }
                html += '<p><span class="only_comment_text" id="edit-comment_'+data.result_data.post_comment_id+'">'+data.result_data.comment_text+'</span>';
                html += '<a class="delete-comment cursor-pointer" onclick="openDeleteCommentConfirmationPopup('+data.result_data.post_comment_id+');"> </a>';
                html += '<a class="edit-comment cursor-pointer" onclick="editComment('+data.result_data.post_comment_id+');"> </a>';

                html += '<div class="edit-comment-div" style="display: none;" id="edit-comments-form_'+data.result_data.post_comment_id+'">';

                html += '<form id="edit_comment_form'+data.result_data.post_comment_id+'" enctype="multipart/form-data" name="edit_comment_form" accept-charset="UTF-8" action="/user/edit-comment" method="POST">';
                html += '<input type="hidden" value="'+data.result_data.csrf_token+'" name="_token">';
                html += '<input type="text" id="edit_comment_'+data.result_data.post_comment_id+'" class="edit-comments" name="edit_comment" value="'+data.result_data.comment_text+'">';
                html += '<button type="button" id="edit_button_'+data.result_data.post_comment_id+'" class="edit_comment_form">Update</button>';
                html += '</form>';


                html += '</div>';
                html += '</p>';
                html += '</div>';
                $('#showComments_'+wall_post_id).append(html);
                post_comments_count_html = '<a class="balon cursor-pointer"></a>'+data.result_data.comments_count
                $('#post_comments_count_'+wall_post_id).html(post_comments_count_html);
            }else{

        }


        }
    });
    return false;

}

function openDeleteCommentConfirmationPopup(comment_id){
    $('#delete-comment-confirmation-modal').modal({
        keyboard: false
    });
    $('#delete_comment_confirm_btn').attr('onclick','deleteComment('+comment_id+')');
    return false;
}

function deleteComment(post_comment_id){
    //alert(post_comment_id);
    jQuery.ajax({
        type: "POST",
        url: "/user/delete-post-comment",
        data:({
            post_comment_id:post_comment_id
        }),
        dataType: 'json',
        success: function(data){
            //console.log(data);
            if(data.status == 'success'){

                var post_id = $("#comment_"+post_comment_id).parents('.user-comment').attr('id');
                var wall_post = post_id.split('_');
                var wall_post_id = wall_post[1];
                var post_comments_count_html = '<a class="balon cursor-pointer"></a>'+data.comments_count;
                $('#post_comments_count_'+wall_post_id).html(post_comments_count_html);
                $("#comment_"+post_comment_id).remove();

            }else{
                alert(data.msg);
            }
            $('#delete-comment-confirmation-modal').modal('hide');


        }
    });
    return false;
}


function likeOrUnlikePost(post_id){
    //alert(post_id+'dddddddddddd'+type);
    var auth_user = $('#auth-user').val();
    if(auth_user == '') {
        window.location = '/user/sign-in';
    }
    else{
    jQuery.ajax({
        type: "POST",
        url: "/user/like-or-unlike-post",
        data:({
            post_id:post_id
        }),
        dataType: 'json',
        success: function(data){
            //console.log(data);
            html = ''
            if(data.status == 'success'){
                if(data.action == 'like'){
                    html += '<a class="heart liked" href="javascript:void(0);" onclick="likeOrUnlikePost('+post_id+');" title="Unlike"></a><span class="likes-count-popup" onclick="getPostLikesUsers('+post_id+');">'+data.current_likes_count+'</span>';

                }else{
                    html += '<a class="heart" href="javascript:void(0);" onclick="likeOrUnlikePost('+post_id+');" title="Like"></a><span class="likes-count-popup" onclick="getPostLikesUsers('+post_id+');">'+data.current_likes_count+'</span>';
                }

                $("#post_count_"+post_id).html(html);
            }else{
                alert(data.msg);
            }


        }
    });
    return false;}
}

function vouchNow(user_id){

    vouch_text = $('#vouch_text').val();
    jQuery.ajax({
        type: "POST",
        url: "/user/vouch-post",
        data:({

            _token: $('#vouchArea').find( 'input[name=_token]' ).val(),
            user_id:user_id,
            vouch_text:vouch_text
        }),
        dataType: 'json',
        success: function(data){
            //console.log(data);
            html = ''
            if(data.status == 'success'){
                location.reload();
            }else{
                alert(data.msg);
            }


        }
    });
    return false;
}

function charLimit(el,maxLength,countFieldId) {
    if (el.value.length > maxLength) return false;
    return true;
}
function characterCount(el,maxLength,countFieldId) {

    var charCount = document.getElementById(countFieldId);

    if (el.value.length > maxLength) el.value = el.value.substring(0,maxLength);
    if (charCount) charCount.innerHTML = maxLength - el.value.length;
    return true;
}


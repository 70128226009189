$( document ).ready(function() {

    //###########################################################
    //##########        Profile Send Message       ##############
    //###########################################################

    var attachmentsCountProfile = 0;
    // clear send message popup
    $('#send-message-profile-link').on('click', function () {
        $('#send-message-modal #send_message_profile_form')[0].reset();
        $('#send-message-modal #info-messages').html('');
        $('#send-message-modal .media-list').html('');
    });

    // send profile message attachments
    $('#send-message-modal #add_attachments').on('click', function(){
        attachmentsCountProfile++;
        $('#send_message_profile_form').append('<input class="attachment file-'+attachmentsCountProfile+'" id="attachment-'+attachmentsCountProfile+'" name="file[]" type="file" />');
        $('#send_message_profile_form #attachment-'+attachmentsCountProfile).click();
        $('#send_message_profile_form #attachment-'+attachmentsCountProfile).css('visibility', 'hidden');

    });
    $('body').on('change', '#send_message_profile_form .attachment', function() {

        if (this.files && this.files[0]) {
            files = this.files[0];
            var reader = new FileReader();
            reader.onload = previewMessageAttachment;
            reader.readAsDataURL(this.files[0]);
        }
    });
    // To Preview Image
    function previewMessageAttachment(e) {

        //        var mimeType = e.target.result.split(",")[0].split(":")[1].split(";")[0].split("/")[0];
        //        if(mimeType == 'image'){
        //
        //            attachment_html = '<div class="file-'+attachmentsCountProfile+' media-list-box">'+
        //            '<img style="height:50px; width:50px" src="'+ e.target.result +'"/>'+
        //            '<a class="cursor-pointer" onclick="$(\'.file-'+attachmentsCountProfile+'\').remove();">x</a>'+
        //            '</div>';
        //        }else{
        //            attachment_html = '<div class="file-'+attachmentsCountProfile+' media-list-box">'+
        //            '<span>'+ $('.file-'+attachmentsCountProfile).val() +'"</span>'+
        //            '<a class="cursor-pointer" onclick="$(\'.file-'+attachmentsCountProfile+'\').remove();">x</a>'+
        //            '</div>';
        //        }

        var attachment_html = '';
        var file_name = $('.file-'+attachmentsCountProfile).val().split('\\').pop();
        var attachment_html = '<div class="file-'+attachmentsCountProfile+' media-list-box">'+
        '<span>'+ file_name +'</span>'+
        '<a class="cursor-pointer" onclick="$(\'#send_message_profile_form .file-'+attachmentsCountProfile+'\').remove();">x</a>'+
        '</div>';

        $('#send_message_profile_form .media-list').append(attachment_html);
    };

    // send message
    $('#send_message_profile_form').on('submit', function(){
        console.log("start");
        $('#loading').show();
        if($('#send_message_profile_form textarea#content').val() != ''){

            $('#send_message_profile_form #plz_wait_message').show();
            $('#send-message-modal #info-messages').hide();
            var form = document.getElementById('send_message_profile_form');

            var formData = new FormData(form);
            if(files){
                $.each(files, function(key, value)
                {
                    formData.append(key, value);

                });
            }

            //console.log(formData);
            var xhr = new XMLHttpRequest();
            // Add any event handlers here...
            xhr.open('POST', form.getAttribute('action'), true);
            xhr.responseType = "text";
            xhr.send(formData);
            xhr.onload = function(e) {
                var new_message_response = xhr.response; // not responseText
                new_message_response = JSON.parse(new_message_response);
                $('#send-message-modal #send_message_profile_form')[0].reset();
                $('#send-message-modal .media-list').html('');
                $('#loading').hide();
                console.log("bbbbbbbbbbbbbbbbbbbbbbbb");
                $('#send_message_profile_form #plz_wait_message').hide();
                $('#send-message-modal #info-messages').show();
                if(new_message_response.status == 'success'){
                    $('#send-message-modal #info-messages').html('<div class="alert alert-success" role="alert">'+new_message_response.msg+'</div>');
                }else if(new_message_response.status == 'warning'){
                    $('#send-message-modal #info-messages').html('<div class="alert alert-warning" role="alert">'+new_message_response.msg+'</div>');
                }else{
                    $('#send-message-modal #info-messages').html('<div class="alert alert-danger" role="alert">'+new_message_response.msg+'</div>');
                }
            }
            return false; // To avoid actual submission of the form

        }else{
            $('#send-message-modal #info-messages').html('<div class="alert alert-danger" role="alert">Please enter some message</div>');
        }
        return false;
    });

    //###########################################################
    //#######        End of Profile Send Message       ##########
    //###########################################################



    var attachmentsCountConveration = 0;
    // get Converstaion with a user
    //    $('.open-conversation-with-user').on('click', function(){
    $(document).on('click', '.open-conversation-with-user', function(e){

        // ajax post method to pass form data to the
        var other_user_id = $(this).data('other-user-id');
        var conversation_id = $(this).data('conversation-id');
        var type = $(this).data('type');
        $('.get-conversation-with-user').removeClass('active');
        $('.get-conversation-with-user.'+conversation_id).addClass('active');
        $.post(
            $(this).data('url'),
            {
                "_token": $('#security_token').find( 'input[name=_token]' ).val(),
                "other_user_id": other_user_id,
                "conversation_id": conversation_id,
                'type' : type,
                'conversation_type' : $('#conversation').data('conversation-type')
            },
            function(data){
                if(data.status == 'success'){
                    $conversation_html = '<h2 class="content-heading">'+data.other_user_name+'</h2>'+
                    '<div class="convers-body">';
                    if(typeof(data.conversation) != 'undefined' && data.conversation != ''){

                        var from_to_class = '';

                        if(data.conversation.messages.length > 0){
                            $.each(data.conversation.messages, function($key, $row){
                                from_to_class = 'to';
                                if($row.sender_id == data.uid){
                                    from_to_class = 'from';
                                }
                                $conversation_html += '<div class="mBoday-'+from_to_class+'">'+
                                '<a href="/user/profile-timeline/'+$row.sender_id+'">';
                                if($row.profile_picture != '' && $row.profile_picture != null){
                                    $conversation_html += '<img src="'+data.profile_pic_path+$row.profile_picture+'">';
                                }else{
                                    $conversation_html += '<img src="/img/no_profile_image.jpeg">';
                                }

                                $conversation_html += '</a><div class="msg-text-body">'+
                                '<p>'+$row.content.replace(/'/g, "\\'")+'</p>';

                                if((data.conversation.attachments[$row.id]).length > 0){
                                    $.each(data.conversation.attachments[$row.id], function($key, $file){
                                        $conversation_html += '<span class="msg-atchmnt"><a href="'+data.attachment_path+''+$file.file_type+'/'+$file.file_name+'" download>'+$file.file_name+'</a></span><br>';
                                    });
                                }

                                $conversation_html += '<span class="msg-dTime">'+moment($row.message_date, "YYYY-MM-DD HH:mm:ss").from(moment(data.now,"YYYY-MM-DD HH:mm:ss"))+'</span>'+
                                //                                $conversation_html += '<span class="msg-dTime">'+messageTimeAgo($row.message_date, data.now)+'</span>'+
                                '</div>'+
                                '<div class="cb"></div>'+
                                '</div>';
                            });
                        }
                    }
                    $conversation_html += '</div>'

                    if($('#conversation').data('conversation-type') != 'trash'){

                        $conversation_html += '<div id="conversation-new-message" data-conversation-id="'+conversation_id+'" class="type-send-msg">'+
                        '<form id="send_message_form" onsubmit="return false;" action="/messages/send" >'+
                        '<input name="content" id="new_message_field" type="text" placeholder="Type your message">'+
                        '<input type="hidden" name="other_user_id" value="'+other_user_id+'">'+
                        '<input type="hidden" name="receiver_id" value="'+other_user_id+'">'+
                        '<input type="hidden" name="conversation_id" value="'+conversation_id+'">'+
                        '<input type="hidden" name="receiver_type" value="conversation">';

                        if($('#conversation').data('messages-type') != 'messages'){
                            $conversation_html += '<input type="hidden" name="conversation_type" value="request">';
                        }else{
                            $conversation_html += '<input type="hidden" name="conversation_type" value="personal">';
                        }

                        $conversation_html += '<input type="hidden" name="_token" value="'+data.token+'">'+
                        '<button id="conversation-send-new-message" >Send</button>'+
                        '<a id="add-conversation-attachments" class="cursor-pointer"></a>'+
                        '<div class="media-list"></div>'+
                        '</form>'+
                        '</div>';
                    }

                    $('#conversation').html($conversation_html);
                    $("#conversation .convers-body").animate({
                        scrollTop: $("#conversation .convers-body")[0].scrollHeight
                    }, 1);

                }
            //response after the process.
            },
            'json'
            );

        return false;
    });


    // send new message from conversations
    $( document ).on( "click", "#conversation #conversation-send-new-message", function(e) {
        if($('#conversation-new-message #new_message_field').val() != ''){

            var message_content = $('#conversation-new-message #new_message_field').val();
            var form = document.getElementById('send_message_form');

            var formData = new FormData(form);
            if(files){
                $.each(files, function(key, value)
                {
                    formData.append(key, value);

                });
            }

            var xhr = new XMLHttpRequest();
            // Add any event handlers here...
            xhr.open('POST', form.getAttribute('action'), true);
            xhr.responseType = "json";
            //            xhr.responseType = "html";
            xhr.send(formData);
            xhr.onload = function(e) {

                var new_message_response = xhr.response;
                $('#conversation-new-message #new_message_field').val('');
                $('#conversation-new-message #send_message_form .media-list').html('');

                if(new_message_response.status == 'success' || new_message_response.status == 'warning'){

                    if(new_message_response.status == 'success'){
                        $('#send-message-response-modal .modal-body').html('<div id="info_messages" class="alert alert-success" role="alert">'+new_message_response.msg+'</div>');
                    }else if(new_message_response.status == 'warning'){
                        $('#send-message-response-modal .modal-body').html('<div id="info_messages" class="alert alert-warning" role="alert">'+new_message_response.msg+'</div>');
                    }

                    // append new message in conversation
                    $conversation_html = '<div class="mBoday-from">'+
                    '<img src="'+new_message_response.p_img+'">'+
                    '<div class="msg-text-body">'+
                    '<p>'+message_content+'</p>';

                    if(new_message_response.attachments.length > 0){
                        $.each(new_message_response.attachments, function($key, $file){
                            $conversation_html += '<span class="msg-atchmnt"><a href="'+new_message_response.attachment_path+''+$file.file_type+'/'+$file.file_name+'" download>'+$file.file_name+'</a></span><br>';
                        });
                    }

                    $conversation_html += '<span class="msg-dTime">Few Seconds ago</span>'+
                    '</div>'+
                    '<div class="cb"></div>'+
                    '</div>';

                    $('#conversation .convers-body').append($conversation_html);
                    $("#conversation .convers-body").animate({
                        scrollTop: $("#conversation .convers-body")[0].scrollHeight
                    }, 1);

                    var conversation_id = $('#conversation-new-message').data('conversation-id');
                    var other_user_id = $('#conversation-new-message #other-user-id').val();

                    // if conversation does not exist, append new conversation in left panel
                    if($('#conversation').data('conversation-type') == 'inbox'){

                        if(!$('.get-conversation-with-user.'+conversation_id).length){

                            var get_conv_url = '/user/messages/conversation';
                            var mark_archive_url = '"change-conversation-status/archive/'+conversation_id+'/'+$('#security_token').find( 'input[name=_token]' ).val()+'"';
                            var mark_trash_url = '"change-conversation-status/trash/'+conversation_id+'/'+$('#security_token').find( 'input[name=_token]' ).val()+'"';
                            //                            if(new_message_response.conversation_type == 'request'){
                            //                                get_conv_url = '/user/project-messages/conversation';
                            //                                mark_archive_url = '"change-conversation-status/archive/'+other_user_id+'/project-messages/'+$('#security_token').find( 'input[name=_token]' ).val()+'"';
                            //                                mark_trash_url = '"change-conversation-status/trash/'+other_user_id+'/project-messages/'+$('#security_token').find( 'input[name=_token]' ).val()+'"';
                            //                            }
                            $left_conversation_html = '<div class="mag-thumb-left get-conversation-with-user '+conversation_id+' active">'+
                            '<div class="msg-thumb-inner">'+
                            '<img src="'+new_message_response.other_p_img+'">'+
                            '<a class="open-conversation-with-user cursor-pointer" id="'+conversation_id+'" data-type="'+new_message_response.conversation_type+'" data-url="'+get_conv_url+'" data-other-user-id="'+other_user_id+'">'+$('#conversation h2.content-heading').html()+'</a>'+
                            '<p><span>few seconds ago</span></p>'+
                            '<div class="mail-thumb-cntrols">'+
                            '<a data-url="'+mark_archive_url+'" class="change-conv-status m-thum-save"></a>'+
                            '<a data-url="'+mark_archive_url+'" class="change-conv-status m-thum-del"></a>'+
                            '</div>'+
                            '<div class="cb"></div>'+
                            '</div>'+
                            '</div>';
                            $('.have-no-conversation').remove();
                            $('.temp-link#'+other_user_id).remove();
                            $('.mail-list-left .mail-nav').after($left_conversation_html);
                        }
                    } else {
                        $('.get-conversation-with-user.'+other_user_id).remove();
                    }

                }else{
                    $('#send-message-response-modal .modal-body').html('<div id="info_messages" class="alert alert-danger" role="alert">'+new_message_response.msg+'</div>');
                }
                $('.attachment').remove();
                $('#send-message-response-modal').modal({
                    keyboard: false
                })
            }
            return false; // To avoid actual submission of the form

        }else{
         /*   $('#send-message-response-modal .modal-body').html('<div id="info_messages" class="alert alert-danger" role="alert">Please enter some message</div>');
            $('#send-message-response-modal').modal({
                keyboard: false
            });*/
        }
        return false;
    });

    // send conversation message attachments
    $( document ).on( "click", "#conversation-new-message #add-conversation-attachments", function(e) {
        attachmentsCountConveration++;
        $('#conversation-new-message #send_message_form').append('<input class="attachment file-'+attachmentsCountConveration+'" id="attachment-'+attachmentsCountConveration+'" name="file[]" type="file" />');
        $('#conversation-new-message #send_message_form #attachment-'+attachmentsCountConveration).click();
        $('#conversation-new-message #send_message_form #attachment-'+attachmentsCountConveration).css('visibility', 'hidden');

    });
    $('body').on('change', '#conversation-new-message #send_message_form .attachment', function() {

        if (this.files && this.files[0]) {
            files = this.files[0];
            var reader = new FileReader();
            reader.onload = previewConversationAttachment;
            reader.readAsDataURL(this.files[0]);
        }
    });
    // To Preview Image
    function previewConversationAttachment(e) {

        var file_name = $('.file-'+attachmentsCountConveration).val().split('\\').pop();
        var attachment_html = '<div class="file-'+attachmentsCountConveration+' media-list-box">'+
        '<span>'+ file_name +'"</span>'+
        '<a class="cursor-pointer" onclick="$(\'#conversation-new-message #send_message_form .file-'+attachmentsCountConveration+'\').remove();">x</a>'+
        '</div>';

        $('#conversation-new-message #send_message_form .media-list').append(attachment_html);
    };

    /* function messageTimeAgo(message_time, now){

        var time = moment(message_time, "YYYY-MM-DD HH:mm:ss").format('DD MMMM, YYYY');

        console.log(moment(message_time, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD H:m:s'));
        console.log(moment(now,"YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD H:m:S'));
//        console.log(moment(message_time, "YYYY-MM-DD HH:mm:ss").fromNow());
        console.log(moment(message_time, "YYYY-MM-DD HH:mm:ss").from(moment(now,"YYYY-MM-DD HH:mm:ss")));

        var message_time_date = moment(message_time, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD');
        var compare_with_now_date = moment(now, "YYYY-MM-DD HH:mm:ss").subtract(1,'day').format('YYYY-MM-DD');

        if(message_time_date == compare_with_now_date){
            time = 'Yesterday';
        }else{
            var days = moment.utc(moment(now,"YYYY-MM-DD HH:mm:ss").diff(moment(message_time,"YYYY-MM-DD HH:mm:ss"))).format("D");
            if(days == 1){
//                var hours = moment.utc(moment(now,"YYYY-MM-DD HH:mm:ss").diff(moment(message_time,"YYYY-MM-DD HH:mm:ss"))).format("H");


                var message_time_hour = moment(message_time, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD H');
                var compare_with_now_hour = moment(now, "YYYY-MM-DD HH:mm:ss").subtract(1,'hour').format('YYYY-MM-DD H');

                if(message_time_hour == compare_with_now_hour){
                    time = '1 Hour ago';
                }else {
                    var hours = moment.utc(moment(now,"YYYY-MM-DD HH:mm:ss").diff(moment(message_time,"YYYY-MM-DD HH:mm:ss"))).format("H");
                    if(hours > 1){
                    time = hours+' Hours ago';
                    }else{
//                        var minutes = moment.utc(moment(now,"YYYY-MM-DD HH:mm:ss").diff(moment(message_time,"YYYY-MM-DD HH:mm:ss"))).format("m");

                        var message_time_minute = moment(message_time, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD H:m');
                        var compare_with_now_minute = moment(now, "YYYY-MM-DD HH:mm:ss").subtract(1,'minute').format('YYYY-MM-DD H:m');

                        if(message_time_minute == compare_with_now_minute){
                            time = '1 Minute ago';
                        } else {

                            var minutes = moment.utc(moment(now,"YYYY-MM-DD HH:mm:ss").diff(moment(message_time,"YYYY-MM-DD HH:mm:ss"))).format("m");
                            if(minutes > 1){
                                time = minutes+' Minutes ago';
                            }else{
                                time = 'Few Seconds ago';
                            }
                        }
                    }
                }


//                if(hours == 2){
//                    time = '1 Hour ago';
//                }else if(hours > 2){
//                    time = hours+' Hours ago';
//                }else{
//                    var minutes = moment.utc(moment(now,"YYYY-MM-DD HH:mm:ss").diff(moment(message_time,"YYYY-MM-DD HH:mm:ss"))).format("m");
//                    if(minutes == 2){
//                        time = '1 Minute ago';
//                    }else if(minutes > 2){
//                        time = minutes+' Minutes ago';
//                    }else{
//                        time = 'Few Seconds ago';
//                    }
//                }
            }
        }


        return time;
    }
    */

    //########################################################
    //####         Change status of conversation          ####
    //########################################################

    $( document ).on( "click", ".mail-thumb-cntrols .change-conv-status", function() {
        var change_status_url = $(this).data('url');
        var move_to = '';
        if( $(this).hasClass('m-thum-save') ){
            move_to = 'Archive';
        }else if( $(this).hasClass('m-thum-del') ){
            move_to = 'Trash';
        }else{
            move_to = 'Inbox';
        }

        $('#send-message-response-modal .modal-body').html('Are you sure you want to move this conversation to '+move_to+'?');
        $('#send-message-response-modal #cnfrm-msg-mdl-btn').attr('change-status-url', change_status_url);
        $('#send-message-response-modal #cnfrm-msg-mdl-btn').show();
        $('#send-message-response-modal').modal({
            keyboard: false
        });
    });

    $('#cnfrm-msg-mdl-btn').on('click', function() {
        window.location.href = $('#cnfrm-msg-mdl-btn').attr('change-status-url');
    });
    $('.conv-mdl-close').on('click', function() {
        $('#send-message-response-modal #change-status-url').attr('change-status-url', '');
        $('#send-message-response-modal #cnfrm-msg-mdl-btn').hide();
    });

//########################################################
//####    End of Change status of conversation        ####
//########################################################

});



$( document ).ready(function() {
    projectButtonClicked = false;
    $( document ).on( "focusout", ".edit-project-comments", function(e){
        //alert('ddddddddd');
        if(!(projectButtonClicked)){
            // do normal focusout code.
            $('.edit-comment-div').hide();
            $('.only_comment_text').show();
        }else{
            // button was clicked - dont execute some code.
            projectButtonClicked = false;
        }
    //console.log(e.currentTarget.children);

    });
    $( document ).on( "mousedown", ".edit_project_comment_form", function(e){

        projectButtonClicked = true;
    });
    var attachmentsUserPostCountProfile = 0;

    // send user wall post attachments
    $('#project_post_form #add_attachments').on('click', function(){
        attachmentsUserPostCountProfile++;
        $('#project_post_form').append('<input class="attachment file-'+attachmentsUserPostCountProfile+'" id="attachment-'+attachmentsUserPostCountProfile+'" name="file[]" type="file" />');
        $('#project_post_form #attachment-'+attachmentsUserPostCountProfile).click();
        $('#project_post_form #attachment-'+attachmentsUserPostCountProfile).css('visibility', 'hidden');

    });
    $('body').on('change', '#project_post_form .attachment', function() {

        if (this.files && this.files[0]) {
            files = this.files[0];
            var reader = new FileReader();
            reader.onload = previewUserPostAttachment;
            reader.readAsDataURL(this.files[0]);
        }
    });
    // To Preview Image
    function previewUserPostAttachment(e) {

        var file_extention = e.target.result.split(",")[0].split(":")[1].split(";")[0].split("/")[1];
        //console.log(file_extention);
        if(file_extention == 'png' || file_extention == 'jpeg' || file_extention == 'jpg'){

            attachment_html = '<div class="file-'+attachmentsUserPostCountProfile+' media-list-box">'+
            '<img style="height:50px; width:50px" src="'+ e.target.result +'"/>'+
            '<a class="cursor-pointer" onclick="$(\'.file-'+attachmentsUserPostCountProfile+'\').remove();">x</a>'+
            '</div>';
        }else if(file_extention == 'pdf'){
            attachment_html = '<div class="file-'+attachmentsUserPostCountProfile+' media-list-box">'+
            '<img style="height:50px; width:50px" src="/img/pdf.png" title="'+$('.file-'+attachmentsUserPostCountProfile).val()+'"/>'+
            '<a class="cursor-pointer" onclick="$(\'.file-'+attachmentsUserPostCountProfile+'\').remove();">x</a>'+
            '</div>';
        }else if(file_extention == 'vnd.openxmlformats-officedocument.presentationml.presentation'){
            attachment_html = '<div class="file-'+attachmentsUserPostCountProfile+' media-list-box">'+
            '<img style="height:50px; width:50px" src="/img/ppt.png" title="'+$('.file-'+attachmentsUserPostCountProfile).val()+'"/>'+
            '<a class="cursor-pointer" onclick="$(\'.file-'+attachmentsUserPostCountProfile+'\').remove();">x</a>'+
            '</div>';
        }else if(file_extention == 'vnd.openxmlformats-officedocument.wordprocessingml.document' || file_extention == 'docx'){
            attachment_html = '<div class="file-'+attachmentsUserPostCountProfile+' media-list-box">'+
            '<img style="height:50px; width:50px" src="/img/doc.png" title="'+$('.file-'+attachmentsUserPostCountProfile).val()+'"/>'+
            '<a class="cursor-pointer" onclick="$(\'.file-'+attachmentsUserPostCountProfile+'\').remove();">x</a>'+
            '</div>';
        }else if(file_extention == 'zip'){
            attachment_html = '<div class="file-'+attachmentsUserPostCountProfile+' media-list-box">'+
            '<img style="height:50px; width:50px" src="/img/zip.png" title="'+$('.file-'+attachmentsUserPostCountProfile).val()+'"/>'+
            '<a class="cursor-pointer" onclick="$(\'.file-'+attachmentsUserPostCountProfile+'\').remove();">x</a>'+
            '</div>';
        }else if(file_extention == 'vnd.ms-excel' || file_extention == 'vnd.openxmlformats-officedocument.spreadsheetml.sheet'){
            attachment_html = '<div class="file-'+attachmentsUserPostCountProfile+' media-list-box">'+
            '<img style="height:50px; width:50px" src="/img/xls.png" title="'+$('.file-'+attachmentsUserPostCountProfile).val()+'"/>'+
            '<a class="cursor-pointer" onclick="$(\'.file-'+attachmentsUserPostCountProfile+'\').remove();">x</a>'+
            '</div>';
        }else if(file_extention == 'plain'){
            attachment_html = '<div class="file-'+attachmentsUserPostCountProfile+' media-list-box">'+
            '<img style="height:50px; width:50px" src="/img/txt.png" title="'+$('.file-'+attachmentsUserPostCountProfile).val()+'"/>'+
            '<a class="cursor-pointer" onclick="$(\'.file-'+attachmentsUserPostCountProfile+'\').remove();">x</a>'+
            '</div>';
        }else{
            attachment_html = '<div class="file-'+attachmentsUserPostCountProfile+' media-list-box">'+
            '<img style="height:50px; width:50px" src="/img/generic.png" title="'+$('.file-'+attachmentsUserPostCountProfile).val()+'"/>'+
            '<a class="cursor-pointer" onclick="$(\'.file-'+attachmentsUserPostCountProfile+'\').remove();">x</a>'+
            '</div>';
        }

        $('#project_post_form .media-list').append(attachment_html);
    };

    // for adding comments
    $( document ).on( "click", ".project_post_comment_form", function(e){
        e.stopPropagation(); // Stop stuff happening
        e.preventDefault();
        comment_textarea_id = e.currentTarget.id;
        //console.log(e.currentTarget);
        addProjectPostComments(comment_textarea_id);
        return false;
    });
    $( document ).on( "keypress", ".project_comments", function(e) {
        if (e.which == 13) {
            comment_textarea_id = e.currentTarget.id;
            addProjectPostComments(comment_textarea_id);
            return false;
        }
    // alert( "Goodbye!" ); // jQuery 1.7+
    });


    $( document ).on( "click", ".project_post_btn", function(e){
        var auth_user = $('#auth-user').val();
        e.stopPropagation(); // Stop stuff happening
        e.preventDefault();

        if(auth_user != undefined)
        {
            $('form#project_post_form').submit();
            return false;
        }
        else{
            window.location.href = "/user/sign-in";
        }

    });

    $('#post').keypress(function (e) {
        if (e.which == 13) {
            e.stopPropagation(); // Stop stuff happening
            e.preventDefault();
            $('form#project_post_form').submit();
            return false;    //<---- Add this line
        }
    });
    // for add security question
    $('#project_post_form').on('submit', function(){
        //console.log(files);
            if ($('textarea#post').val() != '') {
                $('#post-wait').show();
                $("textarea#post").blur();
                var form = document.getElementById('project_post_form');

                var formData = new FormData(form);
                if (files) {
                    $.each(files, function (key, value) {
                        formData.append(key, value);

                    });
                }

                //console.log(formData);
                var xhr = new XMLHttpRequest();
                // Add any event handlers here...
                xhr.open('POST', form.getAttribute('action'), true);
                xhr.responseType = "text";
                xhr.send(formData);
                xhr.onload = function (e) {
                    var arraybuffer = xhr.response; // not responseText
                    //console.log(arraybuffer);
                    transferComplete(arraybuffer);
                    /* ... */
                }
                //xhr.addEventListener("load", transferComplete, false);

                return false; // To avoid actual submission of the form

            }
            return false;
    });

    function transferComplete(data){
        //console.log(data);
        data = JSON.parse(data);
        if(data.status == 'fail'){
            $('#error_messages').html(data.msg);
        }else {
            attachment = data.result_data.attachment;
            attachment_html = '';
            if (attachment != '') {
                attachment_array = attachment.split(',');
                //console.log(attachment_array);
                jQuery.each(attachment_array, function (index, item) {
                    // do something with `item` (or `this` is also `item` if you like)
                    file_type = item.split('.');
                    if (file_type[1] == 'png' || file_type[1] == 'jpg' || file_type[1] == 'jpeg') {
                        attachment_html += '<img class="wall-post-image" src="/' + upload_project_posts_path + 'images/' + item + '">';

                    } else {
                        attachment_html += '<a target= "_blank" download href="/' + upload_project_posts_path + 'files/' + item + '"><img src="/img/generic-big.png" title="' + item + '"></a>';
                    }

                });
            }

            html = '';
            $('textarea#post').val("");

            html += '<div class="user-comment" id="post_' + data.result_data.post_id + '">';
            if (data.result_data.login_user_data.profile_picture == null || data.result_data.login_user_data.profile_picture == '') {
                html += '<div class="view-projectjs-avator"><img src="/img/no_profile_image.jpeg"></div>';
            } else {
                html += '<div class="view-projectjs-avator"><img src="/' + users + 'images/' + data.result_data.login_user_data.profile_picture + '"></div>';
            }


            html += '<div class="user-comm-content">';
            html += '<p>' + data.result_data.post_text + '</p>' + attachment_html;
            html += '<div class="cb"></div>';
            html += '<div class="comm-lower-btns">';

            html += '<div class="left-btns">';
            html += '<span id="post_count_' + data.result_data.post_id + '"><a class="heart" href="javascript:void(0);" onclick="likeOrUnlikePost(' + data.result_data.post_id + ');" title="Like"></a><span class="likes-count-popup">0</span></span>';
            html += '<span id="post_comments_count_' + data.result_data.post_id + '"><a class="balon" href="#"></a>0</span>';
            html += '</div>';

            html += '<div class="right-btns">';
            html += '<a class="del" href="javascript:void(0);" onclick="openDeleteProjectPostConfirmationPopup(' + data.result_data.post_id + ');"></a>';
            html += '<p>Added ' + data.result_data.post_time + '</p>';
            html += '</div>';

            html += '</div>';

            html += '</div>';
            html += '<div class="cb"></div>';
            html += '<div class="a-commented">';
            html += '<div id="showComments_' + data.result_data.post_id + '">';

            html += '</div>';
            html += '<div class="add-a-comment">';
            html += '<form id="form_project_post_comment_' + data.result_data.post_id + '" enctype="multipart/form-data" name="project_post_comment_form" accept-charset="UTF-8" action="/user/add-post-comment" method="POST">';
            html += '<input type="hidden" value="' + data.result_data.csrf_token + '" name="_token">';
            html += '<input type="text" id="project_post_comment_' + data.result_data.post_id + '" class="project_comments" name="comment" placeholder="add your comments" ></textarea>';
            html += '<button type="button" id="project_post_comment_' + data.result_data.post_id + '" class="project_post_comment_form">Submit</button>';
            html += '</form>';
            html += '</div>';
            html += '</div>';
            html += '<div class="cb"></div>';
            html += '</div>';



            $('#all_wall_posts').prepend(html);
            $(".removeFilesClass").remove();
            $(".abcd").remove();
            $(".filedivClass").remove();
            $('#project_post_form .media-list').html('');
            $('#project_post_form .attachment').remove();

        }
        $('#post-wait').hide();
    }

    // for editing comments
    $( document ).on( "click", ".edit_project_comment_form", function(e){
        e.stopPropagation(); // Stop stuff happening
        e.preventDefault();
        comment_textarea_id = e.currentTarget.id;
        //alert(comment_textarea_id);
        //console.log(e.currentTarget);
        editProjectCommentPost(comment_textarea_id);
        return false;
    });
    $( document ).on( "keypress", ".edit-project-comments", function(e) {
        if (e.which == 13) {
            comment_textarea_id = e.currentTarget.id;
            //alert(comment_textarea_id);
            editProjectCommentPost(comment_textarea_id);
            return false;
        }
    // alert( "Goodbye!" ); // jQuery 1.7+
    });


});

function editProjectComment(comment_id){
    $('#edit-comment_'+comment_id).hide();
    $('#edit-comments-form_'+comment_id).show();
    $('#edit_comment_'+comment_id).focus();
    comment_text = $('#edit-comment_'+comment_id).text();
    $('#edit_comment_'+comment_id).val(comment_text);

}


function editProjectCommentPost(comment_textarea_id){
    //alert(post_id);
    comment_textarea = comment_textarea_id.split('_');
    comment_id = comment_textarea[2];
    comment_text = $('#edit_comment_'+comment_id).val();
    //alert(comment_id);
    if(comment_text != ''){
        jQuery.ajax({
            type: "POST",
            url: "/user/edit-comment",
            data:({
                _token: $('#edit_project_comment_form'+comment_id).find( 'input[name=_token]' ).val(),
                comment_text: comment_text,
                comment_id:comment_id
            }),
            dataType: 'json',
            success: function(data){
                //console.log(data);
                if(data.status == 'success'){
                    $('#edit-comments-form_'+comment_id).hide();
                    $('#edit-comment_'+comment_id).html(data.comment_text);
                    $('#edit-comment_'+comment_id).show();
                }

            }
        });
    }
    return false;

}

function openDeleteProjectPostConfirmationPopup(post_id){
    $('#delete-post-confirmation-modal').modal({
        keyboard: false
    });
    $('#delete_post_confirm_btn').attr('onclick','deleteProjectPost('+post_id+')');
    return false;
}

function deleteProjectPost(post_id){
    //alert(post_id);

    jQuery.ajax({
        type: "POST",
        url: "/project/delete-project-post",
        data:({
            post_id:post_id
        }),
        dataType: 'json',
        success: function(data){
            //console.log(data);
            if(data.status == 'success'){
                $("#post_"+post_id).remove();
            }else{
                $('#error_messages').html(data.msg);
            }
            $('#delete-post-confirmation-modal').modal('hide');


        }
    });
    return false;

}

function addProjectPostComments(comment_textarea_id){
    var auth_user = $('#auth-user').val();
    //alert(auth_user);
    if(auth_user != undefined) {
        //alert(comment_textarea_id);
        wall_post = comment_textarea_id.split('_');
        project_post_id = wall_post[3];
        // alert(project_post_id);
        comment_text = $('#' + comment_textarea_id).val();
        $('#' + comment_textarea_id).val('');
        //alert($('#form_'+comment_textarea_id ).find( 'input[name=_token]' ).val());
        jQuery.ajax({
            type: "POST",
            url: "/user/add-project-post-comment",
            data: ({
                _token: $('#form_' + comment_textarea_id).find('input[name=_token]').val(),
                comment_text: comment_text,
                project_post_id: project_post_id
            }),
            dataType: 'json',
            success: function (data) {
                //console.log(data);
                if (data.status == 'success') {
                    $('#' + comment_textarea_id).val("");
                    html = '';
                    html += '<div class="comment-from comment-fixed-height" id="comment_' + data.result_data.post_comment_id + '">';
                    //alert(data.result_data.login_user_data.file_name);
                    if (data.result_data.login_user_data.profile_picture == null) {

                        html += '<img src="/img/no_profile_image.jpeg">';
                    } else {
                        html += '<img src="/' + users + 'images/' + data.result_data.login_user_data.profile_picture + '">';

                    }
                    html += '<p><span class="only_comment_text" id="edit-comment_' + data.result_data.post_comment_id + '">' + data.result_data.comment_text + '</span>';
                    html += '<a class="delete-comment cursor-pointer" onclick="openDeleteProjectCommentConfirmationPopup(' + data.result_data.post_comment_id + ');"></a>';
                    html += '<a class="edit-comment cursor-pointer" onclick="editComment(' + data.result_data.post_comment_id + ');"> </a>';

                    html += '<div class="edit-comment-div" style="display: none;" id="edit-comments-form_' + data.result_data.post_comment_id + '">';

                    html += '<form id="edit_project_comment_form' + data.result_data.post_comment_id + '" enctype="multipart/form-data" name="edit_project_comment_form" accept-charset="UTF-8" action="/user/edit-comment" method="POST">';
                    html += '<input type="hidden" value="' + data.result_data.csrf_token + '" name="_token">';
                    html += '<input type="text" id="edit_comment_' + data.result_data.post_comment_id + '" class="edit-project-comments" name="edit_comment" value="' + data.result_data.comment_text + '">';
                    html += '<button type="button" id="edit_button_' + data.result_data.post_comment_id + '" class="edit_project_comment_form">Update</button>';
                    html += '</form>';


                    html += '</div>';
                    html += '</p>';
                    html += '</div>';
                    $('#showComments_' + project_post_id).append(html);
                    post_comments_count_html = '<a class="balon cursor-pointer"></a>' + data.result_data.comments_count
                    $('#post_comments_count_' + project_post_id).html(post_comments_count_html);
                }
                else {
                }
            }
        });
        return false;
    }
    else {
        window.location.href = "/user/sign-in";
        //$('#unauthorized-modal').modal('show');
    }
}
function openDeleteProjectCommentConfirmationPopup(comment_id){
    $('#delete-comment-confirmation-modal').modal({
        keyboard: false
    });
    $('#delete_comment_confirm_btn').attr('onclick','deleteProjectPostComment('+comment_id+')');
    return false;
}

function deleteProjectPostComment(post_comment_id){
    //alert(post_comment_id);
    jQuery.ajax({
        type: "POST",
        url: "/user/delete-project-post-comment",
        data:({
            post_comment_id:post_comment_id
        }),
        dataType: 'json',
        success: function(data){
            //console.log(data);
            if(data.status == 'success'){

                var post_id = $("#comment_"+post_comment_id).parents('.user-comment').attr('id');
                var wall_post = post_id.split('_');
                var wall_post_id = wall_post[1];
                var post_comments_count_html = '<a class="balon cursor-pointer"></a>'+data.comments_count;
                $('#post_comments_count_'+wall_post_id).html(post_comments_count_html);
                $("#comment_"+post_comment_id).remove();

            }else{
                alert(data.msg);
            }

            $('#delete-comment-confirmation-modal').modal('hide');
        }
    });
    return false;
}


function likeOrUnlikeProjectPost(post_id) {
    var auth_user = $('#auth-user').val();
    //alert(post_id+'dddddddddddd'+type);
    if(auth_user != undefined) {
    jQuery.ajax({
        type: "POST",
        url: "/project/like-or-unlike-project-post",
        data: ({
            post_id: post_id
        }),
        dataType: 'json',
        success: function (data) {
            //console.log(data);
            html = ''
            if (data.status == 'success') {
                if (data.action == 'like') {
                    html += '<a class="heart liked" href="javascript:void(0);" onclick="likeOrUnlikeProjectPost(' + post_id + ');" title="Unlike"></a><span class="likes-count-popup" onclick="getPostLikesUsers(' + post_id + ');">' + data.current_likes_count + '</span>';

                } else {
                    html += '<a class="heart" href="javascript:void(0);" onclick="likeOrUnlikeProjectPost(' + post_id + ');" title="Like"></a><span class="likes-count-popup" onclick="getPostLikesUsers(' + post_id + ');">' + data.current_likes_count + '</span>';
                }

                $("#post_count_" + post_id).html(html);
            } else {
                alert(data.msg);
            }


        }
    });
    return false;
    }
    else{
        window.location.href = "/user/sign-in";
    }
}
$(document).ready(function() {

    var track_click = 1; //track user click on "load more" button, righ now it is 0 click

    var total_pages = $('#load_more_total_pages').val();



    //alert(total_pages);

    //$('#results').load("fetch_pages.php", {'page':track_click}, function() {track_click++;}); //initial data to load


    $("#load_more_button").click(function (e) { //user clicks on button

        var action = $('#load_more_action').val();
        if(action == 'user_timeline'){
            action_url = '/user/get-user-wall-post';
            id = $('#user_id').val();
        }else if(action == 'dashboard'){
            action_url = '/user/get-dashboard-feeds';
            id = $('#limit_id').val();
            //alert(id);
        }else if(action == 'news'){
            action_url = '/news/get-more-news';
            id = '';
        }else{
            action_url = '/project/get-project-wall-post';
            id = $('#project_id').val();
        }
        $(this).hide(); //hide load more button on click
        $('.animation_image').show(); //show loading image

        if(track_click <= total_pages) //user click number is still less than total pages
        {
            //post page number and load returned data into result element
            $.post(action_url,{
                'page': track_click,
                'id':id
            }, function(data) {

                $("#load_more_button").show(); //bring back load more button
                if(action == 'dashboard'){
                    split_data = data.split('||--');

                    data = split_data[0];
                    //console.log(data)
                    $('#limit_id').val(split_data[1]);
                }
                $("#all_wall_posts").append(data); //append data received from server

                //scroll page smoothly to button id
                //                $("html, body").animate({
                //                    scrollTop: $("#load_more_button").offset().top
                //                }, 1000);

                //hide loading image
                $('.animation_image').hide(); //hide loading image once data is received
                if(track_click == total_pages-1) //compare user click with page number
                {
                    $("#load_more_button").hide();
                }else{
                    track_click++; //user click increment on load button
                }

            }).fail(function(xhr, ajaxOptions, thrownError) {//any errors?
                alert(thrownError); //alert with HTTP error
                $("#load_more_button").show(); //bring back load more button
                $('.animation_image').hide(); //hide loading image once data is received
            });


        }

    });
});

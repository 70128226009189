$( document ).ready(function() {
   
    jQuery.validator.setDefaults({
        debug: true,
        success: "valid"
    });

    jQuery('.vldt-form').validate({
        debug: true,
        errorElement: "p",
        errorPlacement: function(error, element) {
            error.appendTo(element.parents(".form-section").find(".vldt-err"));
        },
        rules: {
            
            gender: {
                required: true
            },	
            day: {
                required: true
            },	
            month: {
                required: true
            },	
            year: {
                required: true
            },	
            registered_at: {
                required: true,
                isNotAfterToday : true
            },	
            register_number: {
                required: true,
                maxlength: 255
            },	
            website: {
                url: true
            },	
            registered_nationality: {
                required: true
            },	
            /*education_level: {
                required: false
            },*/
            language_spoken: {
                required: true
            },	
            phone: {
                number: true,
                minlength: 10
            },	
            address: {
                required : true,
                minlength: 3,
                maxlength: 255
            },	
            city: {
                required: true
            },	
            country: {
                required: true
            },	
            skills: {
                minlength: 3
            },	
            personal_interests: {
                minlength: 3
            },	
            /*about: {
                required : false,
                minlength: 3
            }*/
        },
        messages: {
            gender: {
                required: 'required'
            },	
            day: {
                required: 'complete_date_of_birth_is_required'
            },	
            month: {
                required: 'complete_date_of_birth_is_required'
            },	
            year: {
                required: 'complete_date_of_birth_is_required'
            },	
            registered_at: {
                required: 'required'
            },	
            register_number: {
                required: 'required',
                maxlength: 'max 255 characters'
            },	
            website: {
                url: 'invalid'
            },	
            registered_nationality: {
                required: 'required'
            },	
            /*education_level: {
                required: 'required'
            },*/
            language_spoken: {
                required: 'required'
            },	
            phone: {
                number: 'invalid',
                minlength: 'invalid'
            },	
            address: {
                required : 'address is required',
                minlength: 'min 3 characters',
                maxlength: 'max 255 characters'
            },	
            city: {
                required: 'city is required'
            },	
            country: {
                required: 'country is required'
            },	
            skills: {
                minlength: 'min 3 characters'
            },	
            personal_interests: {
                minlength: 'min 3 characters'
            }
            /*about: {
                required : 'required',
                minlength: 'min 3 characters'
            }*/
        },
        highlight: function (label) {
            jQuery(label).closest('.vldt-err p.srvr-err').remove();
            jQuery(label).closest('.vldt-err p').addClass('error');
        },
        invalidHandler: function (event, validator) {
            if($('#language_spoken').val() == null){
                $('.spoken_languages.vldt-err').append('<p class="error">required</p>')
                $('.selectinside.spoken_languages .multiselect').focus();
            }else if($('#language_spoken').val().length > 3){
                $('.spoken_languages.vldt-err').append('<p class="error">maximum 3 languages</p>')
                $('.selectinside.spoken_languages .multiselect').focus();
            }
        },
        submitHandler: function (form) {
            form.submit();
        }
    });

    jQuery('.need-vldt-form').validate({
        debug: true,
        errorElement: "p",
        errorPlacement: function(error, element) {
            error.appendTo(element.parents(".form-section").find(".vldt-err"));
        },
        rules: {
            
            need_category: {
                required : true
            },
            title: {
                required : true,
                minlength: 3,
                maxlength: 255
            },	
            description: {
                required : true,
                minlength: 5
            },	
            quantity: {
                required : true,
                number: true,
                min: 1
            },	
            delivery_method: {
                required : true,
                maxlength: 100
            },	
            last_date: {
                required : true,
                isValidDate : true
            },
            points: {
                checkBadge : true,
                number: true,
                min: 2
            },
            country: {
                checkGlobal: true
            }	
            
        },
        messages: {
            need_category: {
                required : 'required'
            },
            title: {
                required : 'required',
                minlength: 'min 3 characters',
                maxlength: 'max 255 characters'
            },	
            description: {
                required : 'required',
                minlength: 'min 5 characters'
            },	
            quantity: {
                required : 'required',
                number: 'invalid',
                min: 'minimum value is 1'
            },	
            delivery_method: {
                required : 'required',
                maxlength: 'max 100 characters'
            },	
            last_date: {
                required : 'required',
                isValidDate : 'invalid format'
            },
            points: {
                number: 'invalid',
                min: 'minimum value is 2'
            }
            
        },
        highlight: function (label) {
            jQuery(label).closest('.vldt-err p.srvr-err').remove();
            jQuery(label).closest('.vldt-err p').addClass('error');
        },
        invalidHandler: function (event, validator) {
            
            var check_last_date = validate_need_last_date();
            if(check_last_date != 'valid'){
                $('.last-date.vldt-err p').remove();
                $('.last-date.vldt-err').append('<p class="error">'+check_last_date+'</p>');
            }
        },
        submitHandler: function (form) {
            var check_last_date = validate_need_last_date();
            if(check_last_date != 'valid'){
                $('.last-date.vldt-err p').remove();
                $('.last-date.vldt-err').append('<p class="error">'+check_last_date+'</p>');
            }else{
                form.submit();
            }
            
        }
    });



    //#### For project fields validation

    jQuery('.project_form').validate({
        debug: true,
        errorElement: "p",
        errorPlacement: function(error, element) {
            error.appendTo(element.parents(".form-section").find(".vldt-err"));
        },
        ignore : [],
        rules: {
            file :{
                checkFile: true,
      //          extension: "jpg|jpeg|png"
            },
            title: {
                required: true
            },
            tagline: {
                required: true
            },
            website: {
                url: true
            },
            start_date: {
                required : true,
                isValidDate : true,
                /*isGreaterThanToday : true*/
            },
            end_date: {
                isValidDate : true
            },
            category: {
                required: true
            },
            primary_language: {
                required: true
            },
            description: {
                required: true
            },
            phone: {
                number: true,
                minlength: 10
            },
            "country[]": {
                project_locations : true
            }
        },
        messages: {
            file: {
                required: 'required',
            //    extension : 'Invalid file type'
            },
            title: {
                required: 'required'
            },
            tagline: {
                required: 'required'
            },
            website: {
                url: 'Invalid url (i.e http://www.example.com)'
            },
            start_date: {
                required : 'required',
                isValidDate : 'invalid format'
                /*isGreaterThanToday : 'The start date should not be greater or equal to today'*/
            },
            end_date: {
                isValidDate : 'invalid format'
            },
            category: {
                required: 'required'
            },
            primary_language: {
                required: 'required'
            },
            description: {
                required: 'required'
            },
            phone: {
                number: 'invalid',
                minlength: 'invalid'
            }
        },
        highlight: function (label) {
            jQuery(label).closest('.vldt-err p').addClass('error');
        },
        invalidHandler: function (event, validator) {
            
            if($('#language_spoken').val() != null && $('#language_spoken').val().length > 3){
                $('.spoken_languages.vldt-err').append('<p class="error">maximum 3 languages</p>')
                $('.selectinside.spoken_languages .multiselect').focus();
            }
            
            var check_end_date = validate_end_date();
            if(check_end_date != 'valid'){
                $('.end-date.vldt-err p').remove();
                $('.end-date.vldt-err').append('<p class="error">'+check_end_date+'</p>');
                $('#end_date').focus();
            }
        },
        submitHandler: function (form) {
            var submit_form = true;
            if($('#language_spoken').val() != null && $('#language_spoken').val().length > 3){
                $('.spoken_languages.vldt-err').append('<p class="error">maximum 3 languages</p>')
                $('.selectinside.spoken_languages .multiselect').focus();
                submit_form = false;
            }
            
            var check_end_date = validate_end_date();
            if(check_end_date != 'valid'){
                $('.end-date.vldt-err p').remove();
                $('.end-date.vldt-err').append('<p class="error">'+check_end_date+'</p>');
                $('#end_date').focus();
                submit_form = false;
            }
            
            if(submit_form){
                form.submit();
            }
            
        }
    });

    //######## Custom Validation Method for file image
    var customError = '';
    jQuery.validator.addMethod("checkFile", function (value, element) {
        var extension = value.substr( (value.lastIndexOf('.') +1) );
        var validExt = ['jpg','jpeg','png','gif'];
        if(value === ''){
            if(jQuery('#edit_flag').val() === 'true'){
                return true;
            }else{
                jQuery('#image_file-error_code').html('<p class="error">Image Required</p>');
                return false;
            }
        }else if (jQuery.inArray(extension.toLowerCase(),validExt) == -1) {
            if(jQuery('#old_image_thumb').attr('src') != ''){
                return true;
            }else{
                jQuery('#image_file-error_code').addClass("error");
                jQuery('#image_file-error_code').html('Invalid file type of size');
                return false;
            }
        }
        return true;

    }, customError);

    //######## Custom Validation Methodb for Points an Badge
    jQuery.validator.addMethod("checkBadge", function (value, element) {
        
        if (value == '' && !$('#badge').prop('checked')) {
            return false;
        }else{
            return true;
        }
    }, 'Enter Reward and/or select a Badge');
    //######## Custom Validation Methodb Need/Offer/Wish city/country/global
    jQuery.validator.addMethod("checkGlobal", function (value, element) {
        if (!$('#global').prop('checked')) {
            if ($("#country option:selected").val() == '' || $("#city option:selected").val() == '') {
                return false;
            }
        }
        return true;
    }, 'Please select your global location or provide city and country location');
    
    //######## Custom Validation Method for Date formate
    jQuery.validator.addMethod("isValidDate", function(value, element) {
        if(value != ''){
            var validDate = /^(\d{2})\/(\d{2})\/(\d{4})?$/;
            return validDate.test(value);
        }
        return true;
    });

    //######## Custom Validation Method for Date formate
    jQuery.validator.addMethod("isGreaterThanToday", function(value, element) {
        if(value){
            var registered_at = moment.utc(value, "DD/MM/YYYY");
            var today = moment.utc();
            if(registered_at.isAfter(today) || registered_at.diff(today,'days') == 0){
                return false;
            }
        }
        return true;
    }, 'greater than current date');

    //######## Custom Validation Method for Date formate
    jQuery.validator.addMethod("isNotAfterToday", function(value, element) {
        if(value){
            var registered_at = moment.utc(value, "DD/MM/YYYY");
            var today = moment.utc();
            
            if(registered_at.isAfter(today)){
                return false;
            }
        }
        return true;
    }, 'greater than current date'); 
    
    
    //######## Custom Validation Method for project locations
    jQuery.validator.addMethod("project_locations", function (value, element) {
        
        if (!$('#global').prop('checked')) {
            
            locations_count = $( "#dynamic_location_count" ).val();
            //console.log(locations_count);
            for(i=1;i<=locations_count;i++){
                //                console.log("#country-"+i);
                //                console.log($( "#country-"+i ).val());
                if($( "#country-"+i ).val() == '' || $( "#country-"+i ).val() == null){
                    $('.glbl_adrs.vldt-err p.error').remove();
                    //                    console.log('cnt-'+i);
                    return false; 
                }
                if($( "#city-"+i ).val() == '' || $( "#city-"+i ).val() == null){
                    $('.glbl_adrs.vldt-err p.error').remove();
                    //                    console.log('ct-'+i);
                    return false; 
                }
            //                if($( "#zip-"+i ).val() == ''){
            //                    $('.glbl_adrs.vldt-err p.error').remove();
            //                   return false; 
            //                }
            }
        }
        
        return true;
    }, 'Location is not selected properly');
   
    function validate_end_date(){
        if($('#end_date').val() != ''){
            var end_date = moment.utc($('#end_date').val(), "DD/MM/YYYY");
            var yesterday_date = moment.utc().subtract(1,'day');
            var before_start_date = moment.utc($('#start_date').val(), "DD/MM/YYYY").subtract(1,'day');
            
            if(!end_date.isAfter(yesterday_date) || !end_date.isAfter(before_start_date)){
                return 'Please make sure the End date is not before Start and/or Current date';
            }
        }
        return 'valid';
    }
    
    function validate_need_last_date(){
        if($('#last_date').val() != ''){
            var last_date = moment.utc($('#last_date').val(), "DD/MM/YYYY");
            var yesterday_date = moment.utc().subtract(1,'day');
            
            if(!last_date.isAfter(yesterday_date)){
                return 'last date should not be less than current date ';
            }
        }
        return 'valid';
    }
    
});